import * as React from "react";

import Bestellstrecke from "../../Bestellstrecke/Bestellstrecke";

const CGflStep3 = ({
  campaignData,
  handleActiveStepChange,
  handleReset,
  currentPage,
  bestellstrecke,
}) => {
  return (
    <div className={"Step C2Step4 c-Datenschutz"}>
      <div className={"content"}>

        {bestellstrecke && <Bestellstrecke bestellstrecke={campaignData.bestellstrecke.bestellstrecke} currentPage={campaignData.bestellstrecke.bestellstrecke.pages.length + 2} handleActiveStepChange={handleActiveStepChange} campaignuuid={campaignData.uuid} bestellButton={false} />}


        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(currentPage - 1);
            }}
          >
            Zurück
          </div>

          {
            <div
              className={"e-button"}
              onClick={() => {

                handleActiveStepChange(currentPage + 1);
              }}
            >
              Bestätigen und Weiter
            </div>
          }
        </div>
      </div>

    </div>
  );
};

/*<div className={"content-sidebar"}></div>*/

export default CGflStep3;
