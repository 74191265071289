import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../store/auth-context";
import "./CampaignSelect.css";

const Abomodell = () => {
    const [abomodelle, setAbomodelle] = useState([]);
    const [filteredAbomodelle, setFilteredAbomodelle] = useState([]);
    const [filter, setFilter] = useState({ blz: "", name: "" });
    const [newBlz, setNewBlz] = useState("");
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddReqError, setAddReqIsError] = useState(false);

    const apiUrl = process.env.REACT_APP_API_HOST
        ? process.env.REACT_APP_API_HOST
        : "https://sfpapi.videocreator.net";

    useEffect(() => {
        fetchAbomodelle();
    }, []);

    const fetchAbomodelle = async () => {
        try {
            const response = await fetch(apiUrl + "/campaign/abomodelle", {
                headers: { Authorization: `Bearer ${authCtx.token}` },
            });
            const data = await response.json();
            setAbomodelle(data);
            setFilteredAbomodelle(data);
        } catch (error) {
            console.log(error);
        }
    };

    const createAbomodell = async () => {
        try {
            setIsLoading(true);
            await fetch(apiUrl + "/campaign/createAbomodell", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authCtx.token}`,
                },
                body: JSON.stringify({ blz: newBlz }),
            });
            fetchAbomodelle();
            setNewBlz("");
            setIsLoading(false);
            setAddReqIsError(false);
        } catch (error) {
            console.log(error);
            setAddReqIsError(true);
        }
    };

    const updateFilter = (e) => {
        const { name, value } = e.target;
        setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    };

    useEffect(() => {
        if (!filter.blz && !filter.name) {
            setFilteredAbomodelle(abomodelle);
            return;
        }
        const filtered = abomodelle.filter((abomodell) =>
            abomodell?.name?.toLowerCase().includes(filter.name.toLowerCase()) &&
            abomodell.blz.toLowerCase().includes(filter.blz.toLowerCase())
        );
        setFilteredAbomodelle(filtered);
    }, [filter, abomodelle]);

    const onBlzChange = (e) => {
        setNewBlz(e.target.value);
    };

    const handleDeleteAbo = async (blzToDelete) => {
        try {
            setIsLoading(true);
            const response = await fetch(apiUrl + "/campaign/deleteAbomodell", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authCtx.token}`,
                },
                body: JSON.stringify({ blz: blzToDelete }),
            });
            setIsLoading(false);

            if (!response.ok) {
                throw new Error("Failed to delete campaign approval");
            }

            setFilteredAbomodelle((prevAbomodelle) =>
                prevAbomodelle.filter((abomodell) => abomodell.blz !== blzToDelete));

        } catch (error) {
            console.log("Error:", error);
        }
    };

    return (
        <div className="campaign-select-container">
            <h1>Abomodelle</h1>


            <table>
                <thead>
                    <tr>
                        <th>BLZ
                            <div className="blz-filter-container">
                                <input
                                    type="text"
                                    name="blz"
                                    value={filter.blz}
                                    onChange={updateFilter}
                                    placeholder="Filter BLZ"
                                />
                            </div>
                        </th>
                        <th>Name
                            <div className="name-filter-container">

                                <input
                                    type="text"
                                    name="name"
                                    value={filter.name}
                                    onChange={updateFilter}
                                    placeholder="Filter Name"
                                />
                            </div></th>
                        <th>Erstellt am</th>
                        <th>Aktion</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAbomodelle.map((abomodell) => (
                        <tr key={abomodell.blz}>
                            <td>{
                                abomodell.blz}</td>
                            <td>{abomodell?.name}</td>
                            <td>{new Date(abomodell.createdAt).toLocaleString()}</td>
                            <td>
                                <button
                                    className="delete-blz-button-container"
                                    onClick={() => handleDeleteAbo(abomodell.blz)}
                                >
                                    Löschen
                                </button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="blz"
                                value={newBlz}
                                onChange={onBlzChange}
                                placeholder="BLZ hinzufügen"
                                className="new-blz-input"
                            />
                        </td>
                        <td>
                            <div className={`new-blz-button-container${isAddReqError ? " error" : ""}`} onClick={createAbomodell}>
                                {isLoading ? "Lade..." : isAddReqError ? "Fehler" : "Hinzufügen"}
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Abomodell;