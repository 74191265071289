import React, { useEffect, useState, useCallback } from "react";
import jwt from "jwt-decode";

let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  expireTime: 0,
  isLoggedIn: false,
  login: (loginData) => { },
  logout: () => { },
  role: "",
  data: ""
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  expirationTime = expirationTime * 1000;
  //console.log('currentTime: '+currentTime );
  //console.log('expirationTime: '+expirationTime );
  const remainingTime = expirationTime - currentTime;
  //console.log('remainingTime: '+remainingTime );

  return remainingTime;
};

const retrieveStoredToken = () => {
  const storedToken = sessionStorage.getItem("token");
  const storedExpiration = sessionStorage.getItem("expirationTime");
  const role = sessionStorage.getItem("role");
  const data = sessionStorage.getItem("data");

  const remainingTime = calculateRemainingTime(storedExpiration);

  if (remainingTime <= 600000) {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("expirationTime");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("data");
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
    role: role,
    data: data,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();

  let initialToken;
  let initialRole;
  let initialData;
  if (tokenData) {
    initialToken = tokenData.token;
    initialRole = tokenData.role;
    initialData = tokenData.data;
  }

  const [token, setToken] = useState(initialToken);
  const [role, setRole] = useState(initialRole);
  const [data, setData] = useState(initialData);

  const userIsLoggedIn = !!token;

  const loginHandler = (loginData) => {
    const tokenData = jwt(loginData.accessToken);
    const remainingTime = calculateRemainingTime(tokenData.exp);
    setRole(tokenData.role)
    //console.log('remainginTimelogin '+remainingTime)
    logoutTimer = setTimeout(logoutHandler, remainingTime);
    setData(JSON.stringify(loginData.data));
    setToken(loginData.accessToken);
    sessionStorage.setItem("token", loginData.accessToken);
    sessionStorage.setItem("expirationTime", tokenData.exp);
    sessionStorage.setItem("role", tokenData.role);
    sessionStorage.setItem("data", JSON.stringify(loginData.data));
    //console.log(loginData.data);
  };

  const logoutHandler = useCallback(() => {
    setToken(null);
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("expirationTime");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("data");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    data: data,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    role: role,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
