import { createStore, combineReducers } from "redux";

const addBestellungInput = (field, value) => ({
  type: "addBestellungInput",
  payload: {
    field,
    value
  }
});

const removeBestellungInput = field => ({
  type: "removeBestellungInput",
  payload: {
    field
  }
});

const resetBestellung = () => ({
  type: "reset"
});

const addError = (field, message) => ({
  type: "addError",
  payload: {
    field,
    message
  }
});

const removeError = field => ({
  type: "removeError",
  payload: {
    field
  }
});

const resetErrors = () => ({
  type: "resetErrors"
});

const errorReducer = (state = {}, action) => {
  switch (action.type) {
    case "addError":
      return {
        ...state,
        [action.payload.field]: action.payload.message
      };
    case "removeError":
      const newState = { ...state };
      delete newState[action.payload.field];
      return newState;
    case "resetErrors":
      return {};
    default:
      return state;
  }
};


const bestellungReducer = (state = {}, action) => {
  if (action.type === "addBestellungState") {
    state = {
      ...state,
      ...action.payload
    }
  }
  if (action.type === "addBestellungInput") {
    state[action.payload.field] = action.payload.value;
    //console.log("field, value" + action.payload.field + " " + action.payload.value)
    //console.log(JSON.stringify(state));
  }
  else if (action.type === "removeBestellungInput") {
    delete state[action.payload.field];
    // console.log("removedUI" + JSON.stringify(state));
  }
  else if (action.type === "reset") {
    //console.log("reset");
    for (const attribute in state) {
      delete state[attribute];
    }
  }
  return state;
};



const userInputReducer = (state = {}, action) => {
  if (action.type === "addUserInput") {
    state[action.payload.field] = action.payload.value;
    //console.log(JSON.stringify(state));
  }
  else if (action.type === "removeUserInput") {
    delete state[action.payload.field];
    //console.log("removedUI" + JSON.stringify(state));
  }
  else if (action.type === "reset") {
    //console.log("reset");
    for (const attribute in state) {
      delete state[attribute];
    }
  }
  return state;
};

const campaignUUIDReducer = (state = {}, action) => {
  if (action.type === "addCampaignInfo") {

    state =
    {
      ...action.payload
    }

  }
  else if (action.type === "reset") {
    return {};
  }
  return state;
};

const store = createStore(
  combineReducers({
    userInput: userInputReducer,
    campaign: campaignUUIDReducer,
    bestellung: bestellungReducer,
    error: errorReducer
  })
);

export default store;
export {
  addBestellungInput, removeBestellungInput, resetBestellung,
  addError, removeError, resetErrors
};

