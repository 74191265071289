import { useContext, useState, useEffect, useCallback, useRef } from "react";
import AuthContext from "../../store/auth-context";
import * as moment from "moment";
import ReactPaginate from 'react-paginate';


const statusMapping = {
  OFF: "--",
  ON: "Fertig",
  NOT_CHECKED: "In Bearbeitung",
};

const Downloads = ({ campaignUuid, showBLZ }) => {
  const authCtx = useContext(AuthContext);

  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageSelected, setPageSelected] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [totalItems, setTotalItems] = useState(0);

  const [filterBLZ, setFilterBLZ] = useState("");

  const handleFilterChange = (event) => {

    setFilterBLZ(event.target.value);
  };

  let timeout = useRef();

  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";


  const downloadFile = useCallback((async (folder) => {
    setLoading(true);
    const response = await fetch(apiUrl + '/campaign/zip?folder=' + folder, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      responseType: 'blob',
    });


    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = folder + '.zip';
    document.body.appendChild(a);
    a.click();
    a.remove();
    setLoading(false);

  }), [apiUrl, authCtx.token]);

  const getFolderFromLink = (link) => {

    const linkParts = link.split('/');
    if (linkParts.length < 2) return "";
    const folder = linkParts[linkParts.length - 2];
    return folder;
  }

  function Items({ currentItems }) {
    return (
      <>
        {currentItems.map((listRenderjob, indexl) => (
          <div key={indexl} className={"c-CampaignTable"}>
            {!showBLZ && <h3 className={"table-title"}>{listRenderjob[0].campagneName}</h3>}
            {showBLZ && <h3 className={"table-title"}>BLZ: {listRenderjob[0].blz}</h3>}
            <table>
              <tr className={"tablehead"} key={indexl}>
                <td>BEZEICHNUNG</td>
                <td>ERSTELLT AM </td>
                <td>STATUS</td>
                <td>DOWNLOAD</td>
              </tr>
              {listRenderjob.map((downloadItem, indexr) => (
                <tr key={indexr}>
                  <td>
                    {downloadItem.downloadLable ? downloadItem.downloadLable : "--"}
                  </td>
                  <td>{downloadItem.createdAt}</td>
                  <td>{downloadItem.downloadStatus}</td>
                  {downloadItem.downloadStatus === statusMapping.ON ? (
                    <td>
                      <a
                        href={downloadItem.downloadLink}
                        style={{ color: "red" }}
                      >
                        Download
                      </a>
                    </td>
                  ) : (
                    <td>
                      <span style={{ color: "lightgray" }}>Download</span>
                    </td>
                  )}
                </tr>
              ))}
              {listRenderjob.every(item => item.downloadStatus === statusMapping.ON) && listRenderjob.filter(item => !item.downloadLink.endsWith('pdf')).length > 1 && <tr key={indexl + 'l'}>
                <td>Zip</td>
                <td></td>
                <td></td>
                {!loading && <td style={{ color: "red", cursor: "pointer", textDecoration: "underline" }} onClick={() => downloadFile(getFolderFromLink(listRenderjob[0].downloadLink))}>Download Zip</td>}
                {loading && <td style={{ color: "lightgray", cursor: "wait" }}>Erstelle Zip...</td>}

              </tr>
              }
            </table>
          </div>
        ))}
      </>
    );
  }

  const paginatedItems = useCallback(() => {
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = (event) => {
      setPageSelected(event.selected);
    };

    const filteredDownloads = downloads.filter((listRenderjob) =>
      listRenderjob[0].blz.startsWith(filterBLZ)
    );

    return (
      <>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Vor →"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="← Zurück"
          renderOnZeroPageCount={null}
          containerClassName={'pagination'}
          disabledClassName={'disabled-page'}
          nextClassName={"item next "}
          previousClassName={"item previous"}
          breakClassName={'item break-me'}
          activeClassName={'item activePage'}
          pageClassName={'item pagination-page'}
        />

        {showBLZ && (
          <div className="blz-filter">
            <label htmlFor="blz-filter-input">BLZ Filter:</label>
            <input
              type="text"
              id="blz-filter-input"
              value={filterBLZ}
              onChange={handleFilterChange}
            />
          </div>
        )}


        <Items currentItems={filteredDownloads} />

      </>
    );
  }, [downloads, totalItems, itemsPerPage, pageSelected, showBLZ, filterBLZ]);


  const fetchDownloads = useCallback(async () => {
    let url = apiUrl + "/userinput/downloads?"
    url = url + "limit=" + itemsPerPage + "&page=" + pageSelected;
    if (campaignUuid && campaignUuid !== "") {
      url = url + "&filter.campaignuuid=" + campaignUuid;
    }

    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    });
    const resData = await res.json();

    setTotalItems(resData.totalItems);

    const renderjobs = [];

    let index = 0;
    for (let renderjob of resData.downloads) {
      const rowdata = [];
      for (let row of renderjob) {
        const createdat = new Date(row.createdAt);
        const formatedDate = moment(createdat).format("YYYY-MM-DD, HH:mm:ss");

        rowdata.push({
          id: ++index,
          campagneName: row.campagneName,
          createdAt: row.createdAt ? formatedDate : '',
          downloadLink: row.downloadLink,
          downloadLable: row.downloadLable,
          downloadStatus: statusMapping[row.downloadStatus],
          blz: row.blz,
        });
      }
      renderjobs.push(rowdata);
    }
    //check if the renderjobs are the same as the last time, for that we go through the array and check if the downloadLinks are the same
    let same = true;
    if (renderjobs.length === downloads.length) {
      for (let i = 0; i < renderjobs.length; i++) {
        if (renderjobs[i].length === downloads[i].length) {
          for (let j = 0; j < renderjobs[i].length; j++) {
            if (renderjobs[i][j].downloadLink !== downloads[i][j].downloadLink) {
              same = false;
              break;
            }
            if (renderjobs[i][j].downloadStatus !== downloads[i][j].downloadStatus) {
              same = false;
              break;
            }
          }
        } else {
          same = false;
          break;
        }
      }
    } else {

      same = false;
    }

    if (!same) {
      setDownloads(renderjobs);
    }


    if (pageSelected === 0) {
      timeout.current = setTimeout(fetchDownloads, 5000);
    } else if (timeout.current) {
      clearTimeout(timeout.current);
    }


  }, [authCtx, apiUrl, pageSelected, downloads, campaignUuid]);

  useEffect(() => {
    fetchDownloads();

    return function cleanup() {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    };
  }, [fetchDownloads]);

  return (

    <div className={"c-Downloads"}>
      <div className={"page-title"}>
        <div className={"container"}>
          <h2>Downloads</h2>
        </div>
      </div>
      <div className={"container"} style={loading ? { cursor: "wait" } : {}}>
        <div className={"dls-wrapper"}>

          {paginatedItems()}

        </div>
      </div>
    </div>
  );
};

export default Downloads;
