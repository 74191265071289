import Form from "./FormGen";
import './Bestellstrecke.scss';


const overviewHeader = (
    <div className="bestellstrecke__container__content__steps__overview__header">
        <br></br>
        <h2>Bestellung: </h2>
    </div>
);
const bestellstreckeHeader = (
    <div className="bestellstrecke__container__header">
        {/*<h1>{bsplJson?.headline}</h1>*/}
        {/*<h2>{bsplJson?.subheadline}</h2>}
        {/*<p>{bsplJson?.description}</p>*/}
    </div>
)




const Bestellstrecke = ({ bestellstrecke, currentPage, handleActiveStepChange, campaignuuid, redirectHref, bestellButton = true, navigateButton = false }) => {

    return (
        <div className="bestellstrecke">
            <div className="bestellstrecke__container">
                {currentPage <= bestellstrecke.pages.length && bestellstreckeHeader}
                {currentPage > bestellstrecke.pages.length && overviewHeader}
                <div className="bestellstrecke__container__content">
                    <div className="bestellstrecke__container__content__steps">
                        <Form formSchema={bestellstrecke} _currentPage={currentPage} handleActiveStepChange={handleActiveStepChange} campaignuuid={campaignuuid} redirectHref={redirectHref} bestellButton={bestellButton} navigateButton={navigateButton} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bestellstrecke;