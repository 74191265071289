import * as React from "react";
import ReactPlayer from "react-player";
import "./CampaignGFL24.scss"

import AuthContext from "../../../store/auth-context";


const CGflStep0 = ({ campaignData, handleActiveStepChange, setBestellstrecke }) => {
  campaignData.text = "";
  // das hier sollte von der api kommen und dann hier nicht gemacht werden
  const authCtx = React.useContext(AuthContext);
  const authCtxData = JSON.parse(authCtx.data);

  const reactPlayerRef = React.createRef();

  const [videoIndex, setVideoIndex] = React.useState(0);

  const [loopVideo, setLoopVideo] = React.useState(true);

  const [endReached, setEndReached] = React.useState(false);

  // Ref für die Playlist erstellen
  const playlistRef = React.useRef();

  const videoData = [
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/01_PK_PREROLL_Falsche_Ratgeber_20Sek_1920x1080.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "01 PK PREROLL Falsche Ratgeber 20Sek 1920x1080",
      //description: "Main 4",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/03_PK_PREROLL_Friseur_15Sek_1920x1080.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "03 PK PREROLL Friseur 15Sek 1920x1080",
      //description: "Main 4",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/04_PK_PREROLL_Sprachassistent_15Sek_1920x1080.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "04 PK PREROLL Sprachassistent 15Sek 1920x1080",
      //description: "Main 4",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/05_PK_PREROLL_Mama_15Sek_1920x1080.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "05 PK PREROLL Mama 15Sek 1920x1080",
      //description: "Main 4",
    },

    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/06_PK_BUMPER_Friseur_6Sek_1920x1080.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "06 PK BUMPER Friseur 6Sek 1920x1080",
      //description: "Main 4",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/07_PK_BUMPER_Sprachassistent_6Sek_1920x1080.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "07 PK BUMPER Sprachassistent 6Sek 1920x1080",
      //description: "Main 4",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/08_PK_BUMPER_Mama_6Sek_1920x1080.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "08 PK BUMPER Mama 6Sek 1920x1080",
      //description: "Main 4",
    },



    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/21_PK_DOOH_ohne_Ton_Friseur_10Sek_1920x1080.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "21 PK DOOH ohne Ton Friseur 10Sek 1920x1080",
      //description: "Main 4",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/22_PK_DOOH_ohne_Ton_Friseur_10Sek_1080x1920.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "22 PK DOOH ohne Ton Friseur 10Sek 1080x1920",
      //description: "Main 4",
    },

    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/PK_REEL_AD_Mama_1080x1920.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/eigenheim.jpg",
      title: "PK REEL AD Mama 1080x1920",
      //description: "Main 1",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/PK_REEL_AD_Sprachassistent_1080x1920.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/eigenheim.jpg",
      title: "PK REEL AD Sprachassistent 1080x1920",
      //description: "Main 1",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/PK_REEL_AD_Friseur_1080x1920.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "PK REEL AD Friseur 1080x1920",
      //description: "Main 2",
    },




    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/PK_ANIMATION_INTEREST_Weiterbildung_1080x1920.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "PK ANIMATION INTEREST Weiterbildung 1080x1920",
      //description: "Main 4",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/PK_ANIMATION_INTEREST_Moebel_1080x1920.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "PK ANIMATION INTEREST Moebel 1080x1920",
      //description: "Main 4",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/GSK_Privatkredit/PK_ANIMATION_INTEREST_Modernisierung_1080x1920.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "PK ANIMATION INTEREST Modernisierung 1080x1920",
      //description: "Main 4",
    }

  ];

  const nextVideo = React.useCallback(() => {

    console.log('###aasdasd');
    console.log(JSON.stringify(authCtx.data))


    if (videoIndex === videoData.length - 1) {

      setEndReached(true);

      setVideoIndex(0);

    } else {
      setVideoIndex(Number(videoIndex) + 1);
      setEndReached(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoIndex]);

  const changeVideo = React.useCallback(() => {

    reactPlayerRef.current.getInternalPlayer().src = videoData[videoIndex].url;
    if (endReached) {
      reactPlayerRef.current.getInternalPlayer().pause();
    } else {
      reactPlayerRef.current.getInternalPlayer().play();
    }


    //videoUrls nicht als dependency benutzen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactPlayerRef, videoIndex, endReached]);

  const toggleLoop = () => {
    setLoopVideo(!loopVideo);
  }

  const setPlayerOnended = React.useCallback(() => {

    if (reactPlayerRef.current?.getInternalPlayer()) {

      let onEnded = () => { };
      if (loopVideo) {
        onEnded = () => nextVideo();
      }

      reactPlayerRef.current.getInternalPlayer().onended = onEnded;
    }

  }, [loopVideo, nextVideo, reactPlayerRef]);

  React.useEffect(() => {
    setPlayerOnended();
  }, [loopVideo, setPlayerOnended]);

  React.useEffect(() => {
    if (reactPlayerRef.current?.getInternalPlayer()) {

      setPlayerOnended();

      changeVideo();
    }

  }, [videoIndex, reactPlayerRef, setPlayerOnended, changeVideo]);



  // Scroll zur aktuellen Position des aktiven Videos
  React.useEffect(() => {
    if (playlistRef.current && playlistRef.current.children[videoIndex]) {
      const activeVideo = playlistRef.current.children[videoIndex];
      activeVideo.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [videoIndex]);

  /*
   <button className="toggle-loop-button" onClick={() => toggleLoop()}>
            {loopVideo ? '\u25A0 Manuell abspielen' : '\u25B6 Automatisch abspielen'}
          </button>
  */

  const updatePlaylistWidth = () => {
    //console.log('updatePlaylistWidth')
    if (reactPlayerRef.current && playlistRef.current) {
      const videoElement = reactPlayerRef.current.getInternalPlayer();
      if (videoElement) {
        //print all properties of videoElement
        // for (var prop in videoElement) {
        //   console.log(prop, videoElement[prop]);
        // }
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        const aspectRatio = videoWidth / videoHeight;

        // console.log('videoWidth', videoWidth)
        // console.log('videoHeight', videoHeight)
        // console.log('aspectRatio', aspectRatio)

        const wrapper = reactPlayerRef.current.wrapper;
        const containerHeight = wrapper.getBoundingClientRect().height;
        const actualVideoWidth = containerHeight * aspectRatio;

        // console.log('containerHeight', containerHeight)
        // console.log('actualVideoWidth', actualVideoWidth)

        // console.log('current width', playlistRef.current.style.width)
        playlistRef.current.style.width = `${actualVideoWidth}px`;
        // console.log('new width', playlistRef.current.style.width)

      }
    }
  };

  const handleBestellstreckeChange = (value) => {
    setBestellstrecke(value);
  };

  React.useEffect(() => {
    updatePlaylistWidth();
  }, [reactPlayerRef, playlistRef]);

  const kostenMap = {
    "XXS": "199,00 Euro",
    "XS": "249,00 Euro",
    "S": "311,00 Euro",
    "M": "389,00 Euro",
    "L": "486,00 Euro",
    "XL": "607,00 Euro",
  };

  const bilanzKosten = kostenMap[authCtxData?.groesse];

  return (
    <div className={"Step C2Step0 c-Uebersicht"}>
      <div className={"big-video"}>
        <ReactPlayer
          ref={reactPlayerRef}
          url={videoData[videoIndex].url}
          controls={true}
          muted={true}
          onPlay={() => setPlayerOnended()}
          onReady={updatePlaylistWidth}
        />

        <div className="playlist-container" ref={playlistRef}>
          <div className="playlist-title">Playlist</div>

          <div className="video-list">
            {videoData.map((video, index) => (
              <div
                key={index}
                className={`video-item${videoIndex === index ? ' active' : ''}`}
                onClick={() => setVideoIndex(index)}
              >
                {video.thumbnail && <img src={video.thumbnail} alt={`Video ${index + 1}`} />}
                <div className="video-text">
                  <div className="video-title">{video.title}</div>
                  {video.description && <div className="video-description">{video.description}</div>}
                </div>
              </div>
            ))}
          </div>

        </div>

      </div>
      <div className={"content c-KampagnenBeschreibung"}>
        <h5 style={{ color: "grey" }}>Veröffentlicht am 20.02.2024</h5>
        <br />
        <h4>Beschreibung</h4>
        <p>Sie erhalten das komplette Videopaket der GSK Geld fürs Leben – Privatkredit individualisiert mit Ihrem Institutslogo.
        </p>

        <div
          className={"e-button"}
          onClick={() => {
            handleActiveStepChange(1);
            handleBestellstreckeChange(true);
          }}
        >
          Bestellung starten
        </div>


        <br />
        <div className="h-line"></div>
        <h4>Details: </h4>
        <ul>
          <li>Einsetzen des Logos Ihrer Sparkasse in alle Abbinder-Animationen</li>
          <li>optional für SKP-Sparkassen: Integration des SKP-Vermittlerhinweises</li>
          <li>
            Lieferung:
            <ul>
              <li>1x 01 PK PREROLL Falsche Ratgeber 20Sek 1920x1080</li>
              <li>1x 03 PK PREROLL Friseur 15Sek 1920x1080</li>
              <li>1x 04 PK PREROLL Sprachassistent 15Sek 1920x1080</li>
              <li>1x 05 PK PREROLL Mama 15Sek 1920x1080</li>
              <li>1x 06 PK BUMPER Friseur 6Sek 1920x1080</li>
              <li>1x 07 PK BUMPER Sprachassistent 6Sek 1920x1080</li>
              <li>1x 08 PK BUMPER Mama 6Sek 1920x1080</li>
              <li>1x 21 PK DOOH ohne Ton Friseur 10Sek 1920x1080</li>
              <li>1x 22 PK DOOH ohne Ton Friseur 10Sek 1080x1920</li>
              <li>1x PK REEL AD Mama 1080x1920</li>
              <li>1x PK REEL AD Sprachassistent 1080x1920</li>
              <li>1x PK REEL AD Friseur 1080x1920</li>
              <li>1x PK ANIMATION INTEREST Weiterbildung 1080x1920</li>
              <li>1x PK ANIMATION INTEREST Moebel 1080x1920</li>
              <li>1x PK ANIMATION INTEREST Modernisierung 1080x1920</li>
            </ul>
          </li>
          <li>Es gelten für die individualisierten Medien die gleichen zentralen Nutzungsrechte der Kampagne, welche in der Kommunikationswelt-Planung (<a href="https://planung.kommunikationswelt.dsv-gruppe.de">https://planung.kommunikationswelt.dsv-gruppe.de</a>) einzusehen sind.</li>
        </ul>
        <div className="h-line"></div>
        <h4>Kosten: </h4>
        <p>Die Kosten sind nach Bilanzsumme gestaffelt:</p>
        {bilanzKosten && (
          <p>{authCtxData?.bilanzsumme} : <b>{bilanzKosten}</b> zzgl. MwSt.</p>
        )}

      </div>
    </div>
  );
};

export default CGflStep0;
