import * as React from "react";

import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const CSpStep5 = ({ campaignData, handleActiveStepChange }) => {

  let navigate = useNavigate();
  const [isTimeout, setIsTimeout] = React.useState(false);



  let timeout = setTimeout(function () {
    setIsTimeout(true);
    timeout = null
  }, 10000)


  React.useEffect(() => {

    if (isTimeout) {
      navigate('/Downloads', { replace: true })
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (timeout) {
        clearTimeout(timeout)
      }
    };
  });


  //  clearTimeout(this.id);



  return (

    <div className={"Step C2Step5"}>
      <div>
        <span>Das Video steht in kürze Bereit.</span>
        <br />
        <Button component={Link} to={"/Downloads"}>
          Zu den Downloads
        </Button>
      </div>
    </div>
  );
};

export default CSpStep5;
