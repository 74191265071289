import CampaignSelect from "./CampaignSelect";
import Downloads from "../Downloads/Downloads";

import { useState,useContext } from "react";
import AuthContext from "../../store/auth-context";
const KampagnenFreischaltung = () => {

    const authCtx = useContext(AuthContext);

    const [selectedCampaignUuid, setSelectedCampaignUuid] = useState(undefined);

    const handleCampaignSelect = (campaignUuid) => {
        //console.log('handleCampaignSelect: '+campaignUuid);
        setSelectedCampaignUuid(campaignUuid);
    };


    return (<div>
        <h3>Kampagnen-Freischaltungen</h3>
        <CampaignSelect onCampaignSelect={handleCampaignSelect} />
        {selectedCampaignUuid && <Downloads campaignUuid={selectedCampaignUuid} showBLZ={true} />}
    </div>);

}

export default KampagnenFreischaltung;