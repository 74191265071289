// src/components/Admin/Admin.js

import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import "./AdminNavbar.css";
import KampagnenFreischaltung from "./KampagnenFreischaltungen";
import Abomodell from "./Abomodell";
import Overview from "./Overview";
import AuthContext from "../../store/auth-context";

const Admin = () => {
    const authCtx = React.useContext(AuthContext);

    return (
        <div>
            <AdminNavbar />
            <Routes>
                {authCtx.role === "admin" && <Route path="/" element={<Overview/>} />}
                {authCtx.role === "admin" && <Route path="KampagnenFreischaltungen" element={<KampagnenFreischaltung />} />}
                {authCtx.role === "admin" && <Route path="Abomodell" element={<Abomodell />} />}
                {authCtx.role === "kampagnadmin" && <Route path="/" element={<KampagnenFreischaltung />} />}
            </Routes>
        </div>
    );
};

export default Admin;
