import * as React from "react";

import AuthContext from "../../../store/auth-context";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Backdrop, Box } from '@mui/material';

import { addBestellungInput, removeBestellungInput } from "../../../store/redux-store";


const CGflStep2 = ({
  handleActiveStepChange,
  currentPage,
}) => {
  const authCtx = React.useContext(AuthContext);

  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";

  //Bankname vorgelegen
  const field = "kooperation";

  let prevUserInput = useSelector((state) => state.userInput[field]);

  const [isKooperation, setKooperation] = React.useState(prevUserInput === 'true' ? true : false);
  const [open, setOpen] = React.useState(false); // Zustand für Modal öffnen/schließen
  const [selectedImage, setSelectedImage] = React.useState(''); // Zustand für das ausgewählte Bild

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    dispatch(addBestellungInput("Vermittlerhinweis S-Kreditpartner GmbH", isKooperation ? "Ja" : "Nein"));
  }, [isKooperation]);

  const onChangeHandler = (bool) => {

    setKooperation(bool);

    if (bool === true) {

      dispatch({
        type: "addUserInput",
        payload: {
          value: "true",
          field: field,
        },
      });
    }
    else {
      dispatch({
        type: "removeUserInput",
        payload: {
          field: field,
        },
      });
    }
  }

  return (
    <div className={"Step C2Step2 c-Logoauswahl"}>
      <div className={"content"}>
        <h4>
          Benötigen Sie die Werbemittel mit einem Vermittlerhinweis zur S-Kreditpartner GmbH?
        </h4>
        <div>
          <form>
            <input
              type="radio"
              id="ja"
              name="kooperation"
              value="ja"
              checked={isKooperation === true}
              onChange={() => onChangeHandler(true)}
            />
            <label htmlFor="ja">Ja, mit Vermittlerhinweis S-Kreditpartner GmbH</label>
            <br />
            <br />
            <input
              type="radio"
              id="nein"
              name="kooperation"
              value="nein"
              checked={isKooperation === false}
              onChange={() => onChangeHandler(false)}
            />
            <label htmlFor="nein">Nein, ohne Vermittlerhinweis S-
              Kreditpartner GmbH</label>
          </form>

        </div>
        <br />

        <p>Weitere Informationen:</p>
        <p>Der Vermittlerhinweis zur S-Kreditparnter GmbH lautet wie folgt:
          "Ihren Vertrag schließen Sie mit der S-Kreditpartner GmbH, einem auf Ratenkredite spezialisierten Verbundpartnerunternehmen der Sparkassen-Finanzgruppe: www.s-kreditpartner.de."
          <br />
          <br />
          Es wird nur in den folgenden Medien ein Vermittlerhinweis zur S-Kreditpartner GmbH eingefügt:</p>
        <ul>
          <li>1x 01 PK PREROLL Falsche Ratgeber 20Sek 1920x1080</li>
          <li>1x 03 PK PREROLL Friseur 15Sek 1920x1080</li>
          <li>1x 04 PK PREROLL Sprachassistent 15Sek 1920x1080</li>
          <li>1x 05 PK PREROLL Mama 15Sek 1920x1080</li>
          <li>1x 06 PK BUMPER Friseur 6Sek 1920x1080</li>
          <li>1x 07 PK BUMPER Sprachassistent 6Sek 1920x1080</li>
          <li>1x 08 PK BUMPER Mama 6Sek 1920x1080</li>
          <li>1x 21 PK DOOH ohne Ton Friseur 10Sek 1920x1080</li>
          <li>1x 22 PK DOOH ohne Ton Friseur 10Sek 1080x1920</li>
        </ul>
        <p>Die folgenden Medien können ausschließlich ohne Vermittlerhinweis zur S-Kreditpartner GmbH zur Verfügung gestellt werden:</p>
        <ul>
          <li>1x PK REEL AD Mama 1080x1920</li>
          <li>1x PK REEL AD Sprachassistent 1080x1920</li>
          <li>1x PK REEL AD Friseur 1080x1920</li>
          <li>1x PK ANIMATION INTEREST Weiterbildung 1080x1920</li>
          <li>1x PK ANIMATION INTEREST Moebel 1080x1920</li>
          <li>1x PK ANIMATION INTEREST Modernisierung 1080x1920</li>
        </ul>





        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(currentPage - 1);
            }}
          >
            Zurück
          </div>

          <div
            className={"e-button"}
            onClick={() => {
              handleActiveStepChange(currentPage + 1);
            }}
          >
            Weiter
          </div>
        </div>

      </div>
      <div className={"content-sidebar"}>
        <div className={"big-image"}>
          <img
            src="https://dev2.videocreator.net/media/assets/GSK_Privatkredit/ohneVhSpk.jpg" // Ersetzen Sie dies durch den Pfad zu Ihrem Bild
            alt="Beispielbild ohne Vermittlerhinweis"
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpen('https://dev2.videocreator.net/media/assets/GSK_Privatkredit/ohneVhSpk.jpg')} // Ersetzen Sie dies durch den Pfad zu Ihrem Bild
          />
          <p>
            <b>Beispielbild ohne Vermittlerhinweis</b>
          </p>
          <img
            src="https://dev2.videocreator.net/media/assets/GSK_Privatkredit/mitVhSpk.jpg" // Ersetzen Sie dies durch den Pfad zu Ihrem Bild
            alt="Beispielbild ohne Vermittlerhinweis"
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpen('https://dev2.videocreator.net/media/assets/GSK_Privatkredit/mitVhSpk.jpg')} // Ersetzen Sie dies durch den Pfad zu Ihrem Bild
          />
          <p>
            <b>Beispielbild mit Vermittlerhinweis</b>
          </p>
        </div>


      </div>
      {/* Modal-Komponente */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 850, // Passen Sie die Größe nach Bedarf an
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >

          <img src={selectedImage} alt="Bild" style={{ width: '100%' }} />

        </Box>
      </Modal>
    </div >
  );
};

export default CGflStep2;
