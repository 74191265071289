import { useContext } from 'react';

import { Route, Routes, Navigate } from "react-router-dom";

import AuthPage from "./pages/AuthPage";
import DownloadPage from "./pages/DownloadPage";
import CampaignPage from "./pages/CampaignPage";
import AuthContext from './store/auth-context';
import CampaignTwo from "./components/Campaigns/CampaignTwo/CampaignTwo";
import CampaignPodcast from './components/Campaigns/CampaignPodcast/CampaignPodcast';
import CampaignSpayment from './components/Campaigns/CampaignSpayment_23_1/CampaignSpayment';
import CampaignLBS_JJE from './components/Campaigns/CampaignLBS_JJE/CampaignLBS_JJE';
import CampaignLBS_MOD from './components/Campaigns/CampaignLBS_MOD/CampaignLBS_MOD';
import CampaignLBS_SSF from './components/Campaigns/CampaignLBS_SSF/CampaignLBS_SSF';

import CampaignGSK_GFL24 from './components/Campaigns/CampaignGSK_GFL_24_1/CampaignGFL24';

import CampaignLBS_AF from './components/Campaigns/CampaignLBS_AF/CampaignLBS_AF';

import Admin from './components/Admin/Admin';

function App() {
      const authCtx = useContext(AuthContext);


      return (
            <Routes>
                  {!authCtx.isLoggedIn && (<Route path="/" element={<AuthPage />} />)}
                  {authCtx.isLoggedIn && (<Route path="/" element={<CampaignPage />} />)}
                  {!authCtx.isLoggedIn && (<Route path="/auth" element={<AuthPage />} />)}
                  {/*
      {authCtx.isLoggedIn && (<Route path="/kampagneTwo" element={<KampagneTwo />} />)}
*/}
                  {/*authCtx.isLoggedIn && (<Route path="/kampagneTwo" element={<CampaignTwo />} />)*/}
                  {/*authCtx.isLoggedIn && (<Route path="/KampagnePodcast" element={<CampaignPodcast />} />)*/}
                  {authCtx.isLoggedIn && (<Route path="/spayment-dmc2023" element={<CampaignSpayment />} />)}
                  {authCtx.isLoggedIn && (<Route path="/lbs-jugendlichejungeerwachsene" element={<CampaignLBS_JJE />} />)}
                  {authCtx.isLoggedIn && (<Route path="/lbs-modernisiererinnen" element={<CampaignLBS_MOD />} />)}
                  {authCtx.isLoggedIn && (<Route path="/lbs-sofortfinanziererinnen" element={<CampaignLBS_SSF />} />)}
                  {authCtx.isLoggedIn && (<Route path="/gsk-gfl" element={<CampaignGSK_GFL24 />} />)}
                  {authCtx.isLoggedIn && (<Route path="/lbs-anschlussfinanziererinnen" element={<CampaignLBS_AF />} />)}
                  {authCtx.isLoggedIn && (<Route path="/kampagnen" element={<CampaignPage />} />)}
                  {authCtx.isLoggedIn && (<Route path="/downloads" element={<DownloadPage />} />)}
                  {authCtx.isLoggedIn && (authCtx.role === "admin" || authCtx.role === "kampagnadmin") && (<Route path="/admin/*" element={<Admin />} />)}
                  {!authCtx.isLoggedIn && (<Route path="*" element={<AuthPage />} />)}
                  <Route path="*" element={<Navigate to="/" />} />


            </Routes>
      );
}

export default App;
