import * as React from "react";

import "./CampaignSpayment.scss"

const CSpStep4 = ({
  campaignData,
  handleActiveStepChange,
  handleReset,
  currentPage,
  bestellstrecke,
}) => {
  return (
    <div className={"Step C2Step4 c-Datenschutz"}>
      <div className={"content"}>
        <div className={"form-container"}>
          <h4>
            Bitte bestätigen Sie die Nutzungsbedingungen für Medien dieser
            Kampagne.
          </h4>
          <br />
          <h4>Leistung von S-Communication Services</h4>
          <p>
            Die S-Communication Services GmbH („S-Com“) erbringt für das Institut folgende Leistungen:
          </p>
          <ul>
            <li>4 Video-Individualisierungen: </li>
            <ul>
              <li>1x Video im Format 16:9, H.264/MP4, PoS, „Mehr Vielfalt“</li>
              <li>1x Video im Format 16:9, H.264/MP4, Abo-Service, „Außer Puste“</li>
              <li>1x Video im Format 16:9, H.264/MP4, Mobiles Bezahlen, „Große Freiheit“</li>
              <li>1x Video im Format 16:9, H.264/MP4, E-Commerce, „Viel Leidenschaft“</li>
            </ul>
            <li>
              Individualisierung der Videos entsprechend den Angaben in der
              Bestellstrecke
            </li>
          </ul>
          <p>
            Video-Individualisierungen außerhalb der vorgegebenen Optionen sind
            ausgeschlossen.
          </p>

          <h4>Lizenzbedingungen</h4>
          <p>
            Das Institut erhält zum 01.04.2023 bis einschließlich 31.12.2024 das einfache, nicht-exklusive Recht, die erstellten Leistungen im Gebiet der Bundesrepublik Deutschland für die Veröffentlichung im Internet (national) inkl. Paid Media zu nutzen.
          </p>
          <h4>Wirksamwerden/Laufzeit</h4>
          <p>
            Mit Bestätigung des Buttons „Bestätigen und Video(s) erstellen“ akzeptieren Sie die oben genannten Lizenzbedingungen. Der Vertrag endet automatisch mit dem Ablauf der Nutzungsrechte der Videos.
          </p>
          <p>
            Ergänzend gelten die Nutzungsbedingungen VIA sowie die Regelungen des zwischen den Vertragsparteien geschlossenen Rahmenvertrages.{" "}
          </p>
        </div>



        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(currentPage - 1);
            }}
          >
            Zurück
          </div>

          {
            <div
              className={"e-button"}
              onClick={() => {
                handleReset();
                handleActiveStepChange(currentPage + 1);
              }}
            >
              Bestätigen und Video(s) erstellen
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default CSpStep4;
