import React, { useState, useEffect, useContext } from "react";
import "./CampaignSelect.css";
import AuthContext from "../../store/auth-context";
import * as moment from "moment";
import { BestellungModalButton } from "./BestellungModal";



const CampaignSelect = ({ onCampaignSelect }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [blzList, setBlzList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);


  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";

  useEffect(() => {
    async function fetchCampaigns() {
      try {
        const response = await fetch(apiUrl + "/campaign/campaigns", {
          headers: { Authorization: `Bearer ${authCtx.token}` },
        });
        const data = await response.json();
        setCampaigns(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchCampaigns();
  }, []);

  const [filteredBlzData, setFilteredBlzData] = useState([]);

  useEffect(() => {
    async function fetchBlzList() {
      if (selectedCampaign) {
        setIsLoading(true);
        try {
          const response = await fetch(
            apiUrl +
            "/campaign/campaignApprovals?campaignuuid=" +
            selectedCampaign.uuid,
            {
              headers: { Authorization: `Bearer ${authCtx.token}` },
            }
          );
          const data = await response.json();
          setBlzList(data);
          setFilteredBlzData(data);
        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
      }
    }
    fetchBlzList();
  }, [selectedCampaign]);

  const handleFilterBlz = (e) => {
    const filterValue = e.target.value;
    if (filterValue.trim() === "") {
      setFilteredBlzData(blzList);
    } else {
      const filteredList = blzList.filter((item) =>
        item.blz.startsWith(filterValue)
      );
      setFilteredBlzData(filteredList);
    }
  };

  const handleFilterName = (e) => {
    const filterValue = e.target.value;
    if (filterValue.trim() === "") {
      setFilteredBlzData(blzList);
    } else {
      const filteredList = blzList.filter((item) =>
        item.name.toLowerCase().includes(filterValue.toLowerCase())
      );
      setFilteredBlzData(filteredList);
    }
  };

  function handleSelectCampaign(event) {
    const selectedUuid = event.target.value;
    const selectedCampaign = campaigns.find((c) => c.uuid === selectedUuid);
    setSelectedCampaign(selectedCampaign || null);
    if (onCampaignSelect) {
      onCampaignSelect(selectedUuid);
    }
  }


  const [newBlz, setNewBlz] = useState("");
  const [isAddReqError, setAddReqIsError] = useState(false);

  const handleAddNewApproval = async () => {
    if (newBlz.length >= 3) {
      const requestBody = {
        blz: newBlz,
        campaignuuid: selectedCampaign.uuid,
      };

      try {
        setIsLoading(true);
        const response = await fetch(apiUrl + "/campaign/createCampaignApproval", {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authCtx.token}`,
          },
        });

        const data = await response.json();
        setIsLoading(false);

        if (data.statusCode === undefined) {
          console.log('error: false');
          setBlzList([...blzList, { blz: newBlz, name: data.name, createdAt: moment(new Date(data.createdAt)).format('YYYY-MM-DD HH:mm:ss') }]);
          setFilteredBlzData([...filteredBlzData, { blz: newBlz, name: data.name, createdAt: moment(new Date(data.createdAt)).format('YYYY-MM-DD HH:mm:ss') }]);
          setNewBlz("");
          setAddReqIsError(false);
        } else {
          console.log('error: true');
          setAddReqIsError(true);
        }
      } catch (error) {
        console.log(error);
        setAddReqIsError(true);
      }
    }
  };

  const handleDeleteApproval = async (blzToDelete) => {
    try {
      setIsLoading(true);
      const response = await fetch(apiUrl + "/campaign/deleteCampaignApproval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authCtx.token}`,
        },
        body: JSON.stringify({ blz: blzToDelete, campaignuuid: selectedCampaign.uuid }),
      });
      setIsLoading(false);

      if (!response.ok) {
        throw new Error("Failed to delete campaign approval");
      }

      const updatedBlzList = blzList.filter((item) => item.blz !== blzToDelete);
      setFilteredBlzData(updatedBlzList);
      setBlzList(updatedBlzList);

    } catch (error) {
      console.log("Error:", error);
    }
  };

  function handleBlz(event) {
    setNewBlz(event.target.value);
    setAddReqIsError(false);
  };


  return (
    <div className="campaign-select-container">
      <div className="campaign-dropdown-container">
        <select onChange={handleSelectCampaign}>
          <option value="">Wähle eine Kampagne</option>
          {campaigns.map((c) => (
            <option key={c.uuid} value={c.uuid}>
              {c.campagneName}
            </option>
          ))}
        </select>
      </div>
      {selectedCampaign && (
        <div className="selected-campaign-container">
          <h2 className="campaign-title">{selectedCampaign.campagneName}</h2>
        </div>
      )}
      {isLoading && <p>Lade...</p>}

      {selectedCampaign !== null && selectedCampaign.needsApproval === 1 && (
        <table>
          <thead>
            <tr>
              <th>
                BLZ
                <div className="blz-filter-container">
                  <input
                    type="text"
                    placeholder="Filter BLZ"
                    onChange={handleFilterBlz}
                    className="blz-filter-input"
                  />
                </div>
              </th>
              <th>
                Name
                <div className="name-filter-container">
                  <input
                    type="text"
                    placeholder="Filter Name"
                    onChange={handleFilterName}
                    className="name-filter-input"
                  />
                </div>
              </th>
              <th>
                Erstellt am </th>
              <th>Aktion</th>
            </tr>
          </thead>
          <tbody>
            {filteredBlzData && filteredBlzData.map((item) => (
              <tr key={item.blz}>
                <td>{item.blz}</td>
                <td>{item.name}</td>
                <td>{moment(new Date(item.createdAt)).format("YYYY-MM-DD, HH:mm:ss")}</td>
                <td>
                  {
                    item.bestellunguuid &&
                    <BestellungModalButton bestellunguuid={item.bestellunguuid} />
                  }
                  <button
                    className="delete-blz-button-container"
                    onClick={() => handleDeleteApproval(item.blz)}
                  >
                    Löschen
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <input
                  type="text"
                  value={newBlz}
                  onChange={handleBlz}

                  placeholder="BLZ hinzufügen"
                  className="new-blz-input"
                />
              </td>
              <td>
                <div className={`new-blz-button-container${isAddReqError ? " error" : ""}`} onClick={handleAddNewApproval}>
                  {isLoading ? "Lade..." : isAddReqError ? "Fehler" : "Hinzufügen"}
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      )}
      {selectedCampaign !== null && selectedCampaign.needsApproval === 0 &&

        <div className="no-approval-needed-container">
          <p>Keine Freigabe erforderlich</p>
        </div>
      }

    </div>
  );
};

export default CampaignSelect;
