import * as React from "react";
import ReactPlayer from "react-player";
import "./CampaignSpayment.scss"


const CSpStep0 = ({ campaignData, handleActiveStepChange, setBestellstrecke }) => {
  campaignData.text = "";
  // das hier sollte von der api kommen und dann hier nicht gemacht werden


  const reactPlayerRef = React.createRef();

  const [videoIndex, setVideoIndex] = React.useState(0);

  const [loopVideo, setLoopVideo] = React.useState(true);

  const [endReached, setEndReached] = React.useState(false);

  // Ref für die Playlist erstellen
  const playlistRef = React.useRef();

  const videoData = [
    {
      url: "https://dev2.videocreator.net/media/assets/spayment-dmc2023/PoS.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "PoS – Mehr Vielfalt",
      //description: "Main 3",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/spayment-dmc2023/AboService.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/eigenheim.jpg",
      title: "Abo-Service – Außer Puste",
      //description: "Main 1",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/spayment-dmc2023/MobilesBezahlen.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "Mobiles Bezahlen – Große Freiheit",
      //description: "Main 2",
    },
    {
      url: "https://dev2.videocreator.net/media/assets/spayment-dmc2023/eCommerce.mp4",
      //thumbnail: "https://dev2.videocreator.net/media/assets/podcast.jpg",
      title: "E-Commerce – Viel Leidenschaft",
      //description: "Main 4",
    },
  ];

  const nextVideo = React.useCallback(() => {

    if (videoIndex === videoData.length - 1) {

      setEndReached(true);

      setVideoIndex(0);

    } else {
      setVideoIndex(Number(videoIndex) + 1);
      setEndReached(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoIndex]);

  const changeVideo = React.useCallback(() => {

    reactPlayerRef.current.getInternalPlayer().src = videoData[videoIndex].url;
    if (endReached) {
      reactPlayerRef.current.getInternalPlayer().pause();
    } else {
      reactPlayerRef.current.getInternalPlayer().play();
    }


    //videoUrls nicht als dependency benutzen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactPlayerRef, videoIndex, endReached]);

  const toggleLoop = () => {
    setLoopVideo(!loopVideo);
  }

  const setPlayerOnended = React.useCallback(() => {

    if (reactPlayerRef.current?.getInternalPlayer()) {

      let onEnded = () => { };
      if (loopVideo) {
        onEnded = () => nextVideo();
      }

      reactPlayerRef.current.getInternalPlayer().onended = onEnded;
    }

  }, [loopVideo, nextVideo, reactPlayerRef]);

  React.useEffect(() => {
    setPlayerOnended();
  }, [loopVideo, setPlayerOnended]);

  React.useEffect(() => {
    if (reactPlayerRef.current?.getInternalPlayer()) {

      setPlayerOnended();

      changeVideo();
    }

  }, [videoIndex, reactPlayerRef, setPlayerOnended, changeVideo]);



  // Scroll zur aktuellen Position des aktiven Videos
  React.useEffect(() => {
    if (playlistRef.current && playlistRef.current.children[videoIndex]) {
      const activeVideo = playlistRef.current.children[videoIndex];
      activeVideo.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [videoIndex]);

  /*
   <button className="toggle-loop-button" onClick={() => toggleLoop()}>
            {loopVideo ? '\u25A0 Manuell abspielen' : '\u25B6 Automatisch abspielen'}
          </button>
  */

  const updatePlaylistWidth = () => {
    //console.log('updatePlaylistWidth')
    if (reactPlayerRef.current && playlistRef.current) {
      const videoElement = reactPlayerRef.current.getInternalPlayer();
      if (videoElement) {
        //print all properties of videoElement
        // for (var prop in videoElement) {
        //   console.log(prop, videoElement[prop]);
        // }
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        const aspectRatio = videoWidth / videoHeight;

        // console.log('videoWidth', videoWidth)
        // console.log('videoHeight', videoHeight)
        // console.log('aspectRatio', aspectRatio)

        const wrapper = reactPlayerRef.current.wrapper;
        const containerHeight = wrapper.getBoundingClientRect().height;
        const actualVideoWidth = containerHeight * aspectRatio;

        // console.log('containerHeight', containerHeight)
        // console.log('actualVideoWidth', actualVideoWidth)

        // console.log('current width', playlistRef.current.style.width)
        playlistRef.current.style.width = `${actualVideoWidth}px`;
        // console.log('new width', playlistRef.current.style.width)

      }
    }
  };

  const handleBestellstreckeChange = (value) => {
    setBestellstrecke(value);
  };

  React.useEffect(() => {
    updatePlaylistWidth();
  }, [reactPlayerRef, playlistRef]);

  return (
    <div className={"Step C2Step0 c-Uebersicht"}>
      <div className={"big-video"}>
        <ReactPlayer
          ref={reactPlayerRef}
          url={videoData[videoIndex].url}
          controls={true}
          muted={true}
          onPlay={() => setPlayerOnended()}
          onReady={updatePlaylistWidth}
        />

        <div className="playlist-container" ref={playlistRef}>
          <div className="playlist-title">Playlist</div>

          <div className="video-list">
            {videoData.map((video, index) => (
              <div
                key={index}
                className={`video-item${videoIndex === index ? ' active' : ''}`}
                onClick={() => setVideoIndex(index)}
              >
                {video.thumbnail && <img src={video.thumbnail} alt={`Video ${index + 1}`} />}
                <div className="video-text">
                  <div className="video-title">{video.title}</div>
                  {video.description && <div className="video-description">{video.description}</div>}
                </div>
              </div>
            ))}
          </div>

        </div>

      </div>
      <div className={"content c-KampagnenBeschreibung"}>
        <h5 style={{ color: "grey" }}>Veröffentlicht am 01.04.2023</h5>
        <br />
        <h4>Beschreibung</h4>
        <p>
          Im Auftrag der S-Payment GmbH steht Ihnen die Video-Individualisierung der Debit Mastercard-Kampagne 2023 in VIA zur Verfügung.
        </p>
        <div
          className={"e-button"}
          onClick={() => {
            handleActiveStepChange(1);
            handleBestellstreckeChange(false);
          }}
        >
          Individualisierung starten
        </div>


        <br />
        <br />
        <h4>Multi-Zielgruppen-Youtube-Kampagne</h4>
        <div
          className={"e-button"}
          onClick={() => {
            handleActiveStepChange(1);
            handleBestellstreckeChange(true);
          }}
        >
          Bestellung starten
        </div>


        <br />
        <div className="h-line"></div>
        <h4>Details: </h4>
        <ul>
          <li>Einsetzen des Logos Ihrer Sparkasse im Abbinder</li>
          <li>Einsetzen des Logos Ihrer Sparkasse auf den Debit Mastercard-Karten</li>
          <li>Anpassen der Kurz-URL im Abbinder</li>
          <li>
            Lieferung:
            <ul>
              <li>1x Video im Format 16:9, H.264/MP4, PoS, „Mehr Vielfalt“</li>
              <li>1x Video im Format 16:9, H.264/MP4, Abo-Service, „Außer Puste“</li>
              <li>1x Video im Format 16:9, H.264/MP4, Mobiles Bezahlen, „Große Freiheit“</li>
              <li>1x Video im Format 16:9, H.264/MP4, E-Commerce, „Viel Leidenschaft“</li>
            </ul>
          </li>
        </ul>
        <div className="h-line"></div>
        <h4>Kosten: </h4>
        <p>Für die Individualisierung der Videos der Debit Mastercard-Kampagne 2023 fallen keine Kosten an.</p>
      </div>
    </div>
  );
};

export default CSpStep0;
