

import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../../../store/auth-context";

import CSpStep0 from "./CSpStep0"
import CSpStep1 from "./CSpStep1"
import CSpStep2 from "./CSpStep2"
import CSpStep3 from "./CSpStep3"


function getSteps() {
  return ["Übersicht", "Sparkassen-Logo", "Nutzungsbedingungen", "Abschluss"];
}


function Step({ campaignData, activeStep, handleActiveStepChange, handleReset }) {
  switch (activeStep) {
    case 0:
      return (<CSpStep0 campaignData={campaignData} handleActiveStepChange={handleActiveStepChange} />)
    case 1:
      return (<CSpStep1 campaignData={campaignData} handleActiveStepChange={handleActiveStepChange} />)
    case 2:
      return (<CSpStep2 campaignData={campaignData} handleActiveStepChange={handleActiveStepChange} handleReset={handleReset} />)
    case 3:
      return (<CSpStep3 campaignData={campaignData} handleActiveStepChange={handleActiveStepChange} />)

    default: return "Unknown step";
  }
}

export default function CampaignLBS_JJE() {
  const [activeStep, setActiveStep] = React.useState(0);

  function handleActiveStepChange(newValue) {
    setActiveStep(newValue);
  }
  const steps = getSteps();

  const campaignData = useSelector((state) => state.campaign);
  //if campaignData is undefined, redirect to /campaigns
  if ((!campaignData || !campaignData.campagneName) && activeStep <= 1) {
    window.location.href = "/kampagnen";
  }


  const authCtx = useContext(AuthContext);
  let userInputs = useSelector((state) => state.userInput);
  const dispatch = useDispatch();

  if (process.env.REACT_APP_FTP_OUTPUT) {
    userInputs = {
      ...userInputs,
      ftpOutputFolder: process.env.REACT_APP_FTP_OUTPUT,
    };
  }

  let campaignUUID = useSelector((state) => state.campaign.uuid);

  const bodyText = JSON.stringify({
    campaignuuid: campaignUUID,
    userinputKeyValueInput: JSON.stringify(userInputs),
  });

  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";

  const handleReset = () => {
    //console.log(apiUrl + "/userinput/render");

    //console.log(JSON.stringify(bodyText));

    fetch(apiUrl + "/userinput/render", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: bodyText,
    });

    dispatch({
      type: "reset",
    });

    setActiveStep(1);
  };




  //const { from } = location.campaign;
  //console.log(from); // output: "the-page-id"

  return (
    <>
      <div className={"page-title"}>
        <div className={"container"}>
          <h2>{campaignData.campagneName || 'Abschluss'} - Video-Individualisierung</h2>
        </div>
      </div>
      <div className={"container"}>
        <div className={"c-stepsNavigation"}>
          {
            steps.map((label, index) =>
              <div key={"step_" + index} className={"step" + (activeStep === index ? " active" : "") + (activeStep > index ? " finished" : "")}>{label}</div>
            )
          }
        </div>
        <Step activeStep={activeStep}
          handleActiveStepChange={handleActiveStepChange}
          campaignData={campaignData} handleReset={handleReset} />
      </div>
    </>
  );
}
