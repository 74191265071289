import { useContext, useState, useEffect, useCallback } from "react";
import AuthContext from "../../store/auth-context";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';


const Campaigns = () => {
  const authCtx = useContext(AuthContext);

  const [campaigns, setCampaigns] = useState([]);

  const apiUrl = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : 'https://sfpapi.videocreator.net';

  const dispatch = useDispatch();

  dispatch({
    type: "reset",
  });

  const fetchCampaigns = useCallback(async () => {
    try {
      const res = await fetch(apiUrl + "/campaign/campaigns", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      });

      //if unauthorized, redirect to login
      if (res.status === 401) {
        window.location.href = "/auth";
      }
      const resData = await res.json();

      const rowdata = [];

      let index = 0;
      for (let row of resData) {
        rowdata.push({
          id: ++index,
          ...row
        });
      }

      setCampaigns(rowdata);
    }
    catch (error) {
      console.error('error fetching campaigns: ' + error);
      window.location.href = "/auth";
    }
  }, [authCtx, apiUrl]);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);




  const handleOnClick = (value) => {

    dispatch({
      type: "addCampaignInfo",
      payload: {
        ...value
      },
    });

  };


  return (
    <div className={"c-Campaigns"}>
      <div className={"page-title"}>
        <div className={"container"}>
          <h2>Kampagnenübersicht</h2>
        </div>
      </div>
      <div className={"container"}>
        <div className={"cardwrapper"}>
          {campaigns.map((value, index) =>
            <Link key={index} className={"c-CampaignCard"} to={value.frontendLink} state={value} onClick={() => handleOnClick(value)} uuid={value.uuid}>

              <h3>{value.campagneName}</h3>
              <img src={value.imgSrc} alt={value.imgAlt} />
              <div className={"button"}>Individualisieren</div>
            </Link>
          )}
        </div>
      </div>
    </div>

  );

  /*return (
    <section className={classes.profile}>
      <h2>Kampagnenübersicht</h2>
      <KampagnenKarte />
    </section>
  );*/
};

export default Campaigns;
