import React, { useRef, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import { useNavigate } from 'react-router-dom';
import classes from './AuthForm.module.css';

const AuthForm = () => {

  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [passwortForg, setPasswortForg] = React.useState(false);
  const [freischaltungTog, setFreischaltungTog] = React.useState(false);
  const [loginMessage, setLoginMessage] = React.useState("");
  const [loginMessageClass, setLoginMessageClass] = React.useState("");

  const apiUrl = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : 'https://sfpapi.videocreator.net';



  const submitHandler = async (event) => {
    try {
      setLoginMessageClass("");

      event.preventDefault();
      const enteredEmail = emailInputRef.current.value;
      const enteredPassword = passwordInputRef.current.value;

      //add validation
      //console.log(apiUrl);

      const res = await fetch(apiUrl + "/auth/signin", {
        method: "POST",
        body: JSON.stringify({
          username: enteredEmail,
          password: enteredPassword
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const result = await res.json();

      if (result && result.message) {
        setLoginMessage(result.message);

        setLoginMessageClass(classes.error);
      }

      if (result && result.accessToken) {
        setLoginMessage("");
        authCtx.login(result);
        navigate('/');
      }

    } catch (error) {
      console.log(error);
      authCtx.logout()
    }


  }

  const toogleShowPassword = () => {
    setPasswortForg(!passwortForg)
  };
  const toogleFreoschaltung = () => {
    setFreischaltungTog(!freischaltungTog)
  };

  return (
    <section className={classes.auth}>
      <h1>Login VIA<br /></h1>
      <h3>Video-Individualisierung, automatisiert</h3>
      <br />
      <br />
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor='email'>Nutzername</label>
          <input type='text' id='text' required ref={emailInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor='password'>Passwort</label>
          <input type='password' id='password' required ref={passwordInputRef} />
        </div>
        <p className={loginMessageClass}>{loginMessage}</p>
        <div className={classes.actions}>
          <button>{'Login'}</button>
        </div>
      </form>
      <div className={classes.pw} onClick={() => { toogleFreoschaltung() }}><p><u>Keine Freischaltung?</u></p></div>
      {freischaltungTog && <p>
        Besitzen Sie noch keine Freischaltung: Beantragen Sie diese bitte <a href='https://mailing.sparkasse.de/-lp/fvNkl8380/V1fJX507'><b><u>hier</u></b></a>.
      </p>}
      <div className={classes.pw} onClick={() => { toogleShowPassword() }}><p><u>Passwort vergessen?</u></p></div>
      {passwortForg && <p>
        Haben Sie Ihr Passwort für Ihren freigeschaltet S-Extranet-User vergessen?<br /><br />
        So erreichen Sie uns:<br />
        S-Communication Services GmbH<br />
        Friedrichstraße 50<br />
        10117 Berlin<br /><br />
        Erreichbarkeit:<br />
        Mo.-Fr. 08:00-17:00 Uhr<br />
        Tel.: 030 246 36 -700<br />
        E-Mail: <a href='mailto:support@sparkasse.de'>support@sparkasse.de</a><br />

      </p>}
    </section>
  );
};

export default AuthForm;
