import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../store/auth-context";
//import css
import "./Overview.css";
import DateConverter from "../Common/DateConverter";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  LineController
} from "chart.js";




const Overview = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    LineController
  );

  const authCtx = useContext(AuthContext);
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchStats = async () => {
    setIsLoading(true);
    const apiUrl = process.env.REACT_APP_API_HOST
      ? process.env.REACT_APP_API_HOST
      : "https://sfpapi.videocreator.net";

    const response = await fetch(apiUrl + "/userinput/stats", {
      headers: { Authorization: `Bearer ${authCtx.token}` },
    });

    const data = await response.json();
    setIsLoading(false);
    setStats(data);
  };

  useEffect(() => {
    fetchStats();
  }, [authCtx.token]);

  const handleReloadClick = () => {
    fetchStats();
  };

  const sumOfLastThirtyDaysRenderJobsDone = stats?.lastThirtyDaysRenderJobsDone?.reduce(
    (acc, cur) => acc + cur.count,
    0
  );

  const startingValue = stats?.totalRenderJobsDone - sumOfLastThirtyDaysRenderJobsDone;

  const cumulativeRenderJobsDone = stats?.lastThirtyDaysRenderJobsDone?.map(
    (item, index, array) => ({
      ...item,
      count: array.slice(0, index + 1).reduce((acc, cur) => acc + cur.count, startingValue),
    })
  );

  const filledLastThirtyDaysRenderJobsDone = DateConverter.fillMissingDates(cumulativeRenderJobsDone, true);
  const blzRenderings = DateConverter.fillMissingDates(stats?.numberOfDifferentBLZsLastThirtyDaysPerDay, false);


  const renderJobsChartData = {
    labels: filledLastThirtyDaysRenderJobsDone?.map((item) =>
      DateConverter.formatIsoToDDMMYY(item.date)
    ),
    datasets: [
      {
        type: 'bar',
        label: "Anzahl unterschiedlicher BLZs (pro Tag)",
        data: blzRenderings?.map((item) => item.count),
        backgroundColor: "rgb(255, 99, 132)",
        tension: 0.1,
        yAxisID: "y1",
      },
      {
        type: 'line',
        label: "Erfolgreiche Renderings",
        data: filledLastThirtyDaysRenderJobsDone?.map((item) => item.count),
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
        yAxisID: "y"
      }

    ],
  };



  const renderJobsChartOptions = {
    responsive: true,
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
        position: "right"
      },
      y1: {
        type: "linear",
        beginAtZero: true,
        position: "left",
        suggestedMax: Math.max(...blzRenderings.map(item => item.count)) + 10,
        grid: {
          display: false
        }

      }
    },
    maintainAspectRatio: true,
  };


  // if (!stats) {
  //   return <div>
  //     <h3>Übersicht</h3>
  //     Lade...
  //   </div>;
  // }

  if (stats?.lastThirtyDaysRenderJobsDone) {
    stats.lastThirtyDaysRenderJobsDone = DateConverter.fillMissingDates(stats?.lastThirtyDaysRenderJobsDone, false);
  }

  //calculate the sum of the last 7 days
  let sumOfLastSevenDaysRenderJobs = stats?.lastThirtyDaysRenderJobsDone?.slice(stats?.lastThirtyDaysRenderJobsDone.length - 7).reduce((acc, cur) => acc + cur.count, 0);

  //calculate the sum of the last 30 days
  let sumOfLastThirtyDaysRenderJobs = stats?.lastThirtyDaysRenderJobsDone?.reduce((acc, cur) => acc + cur.count, 0);


  return (
    <div>
      <h3>Übersicht{isLoading ? " - Lädt..." : ""}</h3>
      <button onClick={handleReloadClick}>Statistiken aktualisieren</button>
      <div className={"overviewContainer"}>

        <div className={"box"}>
          <h4>Allgemeine Statistiken</h4>

          <table className="tableNoBorder">
            <tbody>
              <tr>
                <td>Abgeschlossene Renderings</td>
                <td>
                  {stats?.totalRenderJobsDone}
                  <span className="lastOneDay legend"> + {(stats?.lastThirtyDaysRenderJobsDone[stats?.lastThirtyDaysRenderJobsDone.length - 1]?.count) ?? 0}</span>
                  <span className="lastSevenDays legend"> + {sumOfLastSevenDaysRenderJobs}</span>
                  <span className="lastThirtyDays legend"> + {sumOfLastThirtyDaysRenderJobs}</span>
                </td>
              </tr>
              <tr>
                <td>Aktuell laufende Renderings</td>
                <td>{stats?.numberOfCurrentRenderJobs}</td>
              </tr>
              <tr>
                <td>BLZs, die Videos gerendert haben</td>
                <td>
                  {stats?.numberOfDifferentBLZsTotal}
                  <span className="lastOneDay legend"> / {stats?.numberOfDifferentBLZsLastOneDay}</span>
                  <span className="lastSevenDays legend"> / {stats?.numberOfDifferentBLZsLastSevenDays}</span>
                  <span className="lastThirtyDays legend"> / {stats?.numberOfDifferentBLZsLastThirtyDays}</span>
                </td>
              </tr>
            </tbody>
          </table>

          <p className="legend">
            <span className="lastOneDay">+ Heute </span>
            <span className="lastSevenDays">+ Letzten 7 Tage </span>
            <span className="lastThirtyDays">+ Letzten 30 Tage</span>
          </p>

        </div>
        <div className={"box"}>
          <h4>Renderings der letzten 30 Tage (kumuliert)</h4>
          {!isLoading && <Line data={renderJobsChartData} options={renderJobsChartOptions} height={80} />}
        </div>
        <div className={"box"}>
          <h4>Am meisten gerenderten Kampagnen der letzten 7 Tage</h4>
          <table className="tableNoBorder">
            <tbody>
              {stats?.rendersPerCampaignLastSevenDays?.map((item) => (
                <tr key={item.campaignName}>
                  <td>{item.campaignName}</td>
                  <td>{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={"box"}>
          <h4>Am meisten gerenderten Kampagnen der letzten 30 Tage</h4>
          <table className="tableNoBorder">
            <tbody>
              {stats?.rendersPerCampaignLastThirtyDays?.map((item) => (
                <tr key={item.campaignName}>
                  <td>{item.campaignName}</td>
                  <td>{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={"box"}>
          <h4>BLZs mit am meisten gerenderten Kampagnen der letzten 7 Tage</h4>
          <table className="tableNoBorder">
            <tbody>
              {stats?.blzRequestsLastSevenDays?.map((item) => (
                <tr key={item.campaignName}>
                  <td>{item.blz}</td>
                  <td>{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={"box"}>
          <h4>BLZs mit am meisten gerenderten Kampagnen der letzten 30 Tage</h4>
          <table className="tableNoBorder">
            <tbody>
              {stats?.blzRequestsLastThirtyDays?.map((item) => (
                <tr key={item.campaignName}>
                  <td>{item.blz}</td>
                  <td>{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>


        <div className={"box"} style={{ marginRight: "50.5%" }}>
          <h4>Letzten maximal 20 Logins der letzten 30 Tage</h4>
          <table className="tableNoBorder">
            <tbody>
              {stats?.loginsLastSevenDays?.map((item) => (
                <tr key={item.createdAt}>
                  <td>{item.blz}</td>
                  <td>{DateConverter.formatIsoToDDMMYYHHMM(item.loginTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
};

export default Overview;
