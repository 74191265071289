import React, { useState, useContext, useEffect, useCallback } from 'react';
import AuthContext from "../../store/auth-context";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addError, removeError, addBestellungInput, removeBestellungInput } from "../../store/redux-store";

function Form({ formSchema, _currentPage, handleActiveStepChange, campaignuuid, redirectHref, bestellButton = true, navigateButton = false }) {

    const [currentPage, setCurrentPage] = useState(_currentPage ? _currentPage : 1); // Anfangsseite ist 1s

    const [email, setEmail] = useState('');

    const [authData, setAuthData] = useState({});

    const dispatch = useDispatch();

    const state = useSelector((state) => state.bestellung);
    const errorState = useSelector((state) => state.error);

    let navigate = useNavigate();

    const safeInputRegex = /^[a-zA-Z0-9 _.@+=,',;#äüöÄÜÖ?ß!()\[\]§&%$€\-]*$/;

    const [dataSelectState, setDataSelectState] = useState([]);

    const authCtx = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_HOST
        ? process.env.REACT_APP_API_HOST
        : "https://sfpapi.videocreator.net";

    const currentFormPage = formSchema.pages.find(page => page.page === currentPage);

    useEffect(() => {
        const prefillState = {};

        let authxData = {};
        try {
            authxData = JSON.parse(authCtx.data);
        } catch (error) {
            console.error('error parsing authCtx.data: ' + error);
            console.error('authCtx.data: ' + authCtx.data);
        }

        setAuthData(authxData);

        // Loop over each field to see if it has a prefill property
        let newDataSelectState = [...dataSelectState];

        currentFormPage?.fields?.forEach(field => {
            if (field.prefill && !state[field.label] && field.type !== 'checkboxGen') { // Checking that state doesn't already have a value
                prefillState[field.label] = authxData[field.prefill];
            }

            if (field.type === 'checkboxGen' && field?.defaultValue === 'checked') {
                const checkboxGenOptions = authData[field.content];
                checkboxGenOptions?.forEach((option, optionIndex) => {
                    //console.log("option: " + option + " optionIndex: " + optionIndex);

                    if (state[labelWithOptionIndex(field.label, optionIndex)]) {
                        //console.log("labelWithOptionIndex(field.label, optionIndex): " + labelWithOptionIndex(field.label, optionIndex));
                        return;
                    }
                    dispatch(addBestellungInput(labelWithOptionIndex(field.label, optionIndex), option));
                });
            }

            //den alten DataselectState wiederherstellen
            if (field?.options && field?.dataSelectorIndex) {
                let currentOption = field.options.find(option => option.value === state[field.label] || option.value === authxData[field.prefill]);

                let currentDateSelector = currentOption?.dataSelector?.toLocaleLowerCase();

                if (currentDateSelector) {
                    newDataSelectState[field.dataSelectorIndex - 1] = currentDateSelector;
                } else {
                    newDataSelectState[field.dataSelectorIndex - 1] = null;
                }
            }
        });
        setDataSelectState(newDataSelectState);



        dispatch({
            type: "addBestellungState",
            payload: {
                ...prefillState
            }
        });
    }, [currentFormPage, authCtx]);


    useEffect(() => {

        currentFormPage?.fields?.forEach(field => {
            if (field.type === 'textDataSelect') {
                //console.log("textDataSelect: " + field.label);
                dataSelect(field.label);
            }

        });
        //console.log("dataSelectState: " + JSON.stringify(dataSelectState));

    }, [dataSelectState]);

    function handleInputChangeNonText(label, value) {
        //console.log("handleInputChangeNonText: " + label + " " + value);
        handleInputChange(label, value, null, null, false);
    }

    function handleInputChangeNonTextRememberFalse(label, value) {
        //console.log("handleInputChangeNonTextRememberfalse: " + label + " " + value);
        handleInputChange(label, value, null, null, false, true);
    }

    function handleInputChange(label, value, pattern, errorMessage, patternCheck = true, rememberFalse = false) {
        const regex = pattern ? new RegExp(pattern) : safeInputRegex;

        // Check if value matches the pattern
        const valueMatchesPattern = regex.test(value);
        if (!valueMatchesPattern && value && patternCheck) {
            dispatch(addError(label, errorMessage || "Formatfehler"));
        } else {
            dispatch(removeError(label));
        }

        if (!value && !rememberFalse) {
            //console.log("removeBestellungInput: " + label);
            dispatch(removeBestellungInput(label));

            // If other fields depend on this label, you might also want to reset them.
            formSchema.pages.forEach(page => {
                page.fields.forEach(field => {
                    if (field.dependsOn === label) {
                        dispatch(removeBestellungInput(field.label));
                    }
                });
            });

        }
        else {
            dispatch(addBestellungInput(label, !value && rememberFalse ? false : value));

            formSchema.pages.forEach(page => {
                page.fields.forEach(field => {
                    if (field.dependsOn === label) {
                        dispatch(addBestellungInput(field.label, null));
                    }
                });
            });
        }
        //console.log("bestellung: " + JSON.stringify(state));

        //console.log("label: " + label + " value: " + value);

        let currentField = currentFormPage?.fields?.find(field => field.label === label);
        if (currentField?.options) {
            let currentOption = currentField.options.find(option => option.value === value);
            let currentOptionDataSelectorIndex = currentField?.dataSelectorIndex;
            if (currentOption?.dataSelector && currentOptionDataSelectorIndex) {
                //set value in dataSelectState
                let newDataSelectState = [...dataSelectState];
                //console.log("currentOptionDataSelectorIndex: " + currentOptionDataSelectorIndex);
                newDataSelectState[currentOptionDataSelectorIndex - 1] = currentOption?.dataSelector;
                setDataSelectState(newDataSelectState);
                //console.log("new dataSelectState: " + JSON.stringify(newDataSelectState));
            }
        }
    }



    const handleNext = () => {
        setCurrentPage(currentPage + 1);
        handleActiveStepChange(currentPage + 1);
    };

    const handleBack = () => {
        if (currentPage > 1) { // verhindert ein Unterlaufen des Seitenindexes
            setCurrentPage(currentPage - 1);
            handleActiveStepChange(currentPage - 1);
        }
        if (currentPage === 1) {
            handleActiveStepChange(0);
        }

    };

    function isValidField(field) {
        if (field.dependsOn) {
            if (field?.dependsOnValueCondition === "startsWith" && typeof state[field.dependsOn] === 'string') {
                return state[field.dependsOn].startsWith(field.dependsOnValue);
            }
            else if (field?.dependsOnValueCondition === "endsWith" && typeof state[field.dependsOn] === 'string') {
                return state[field.dependsOn].endsWith(field.dependsOnValue);
            }
            else if (field?.dependsOnValueCondition === "includes" && typeof state[field.dependsOn] === 'string') {
                return state[field.dependsOn].includes(field.dependsOnValue);
            }
            else if (field.dependsOnValue === '+++' && state[field.dependsOn]?.length > 0) {
                return true;
            } else {
                return state[field.dependsOn] === field.dependsOnValue;
            }
        }
        return true;
    }


    const allRequiredFieldsFilled = currentFormPage?.fields.every(field => {
        //return true;
        if (field.isRequired) {
            const gesetzt = state[field.label] && state[field.label].length > 0;
            // if (!gesetzt) {
            //     console.log("field.label: " + field.label + " gesetzt: " + gesetzt);
            //     console.log("isValidField: " + isValidField(field));
            // }
            return gesetzt || !isValidField(field);
        }
        return true;
    });

    const allFieldsValid = currentFormPage?.fields.every(field => {
        //return true;
        if (field.pattern) {
            return !(errorState[field.label] && errorState[field.label].length > 0);
        }
        return true;
    });

    const bodyText = JSON.stringify({
        campaingUUID: campaignuuid,
        bestellStrecke: JSON.stringify(state),
        empfaengerEmail: email,
    });

    const logState = useEffect(() => {
        //console.log("state: " + JSON.stringify(state));
        //console.log("errorState: " + JSON.stringify(errorState));
    }, [state, errorState]);

    const handleSubmit = async () => {
        //console.log("handleSubmit: " + allRequiredFieldsFilled + " " + allFieldsValid)

        //console.log("Form submitted: " + JSON.stringify(state));

        //console.log("userdata:" + JSON.stringify(authCtx.data));

        const responseFetch = await fetch(apiUrl + "/userinput/bestellung", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            },
            body: bodyText,
        });

        const bestellungResponse = await responseFetch.json();

        handleActiveStepChange(0);
    };





    if (currentPage >= formSchema.pages.length + 1) {
        // Wenn wir auf der letzten Seite sind, zeigen wir die gesammelten Daten an
        return (
            <div className="form-container">
                <p>Ihre eingegebenen Daten im Überblick:</p>
                <table>
                    <tbody>
                        {formSchema.pages.map(page => {
                            return page.fields.map(field => {
                                // Sammle alle Schlüssel, die mit einer Zahl gefolgt von einem Unterstrich und dann dem `field.label` beginnen.
                                const matchingKeys = Object.keys(state).filter(
                                    key => key.match(/^\d+_/) && key.endsWith(`_${field.label}`)
                                );

                                // Konkatiniere die Werte dieser Schlüssel.
                                const concatenatedValue = matchingKeys
                                    .map(key => state[key])
                                    .join(", ");

                                const displayValue = concatenatedValue || state[field.label];

                                return (
                                    displayValue && (
                                        <tr key={field.label}>
                                            <td>{field.label}</td>
                                            <td>{displayValue.split('\n').map((item, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 && <br />}
                                                    {item}
                                                </React.Fragment>
                                            ))}</td>
                                        </tr>
                                    )
                                );
                            });
                        })}
                    </tbody>

                </table>
                {bestellButton &&
                    <div className="button-container">
                        <button type="button" onClick={handleBack}>Zurück</button>
                        <button type="submit" onClick={handleSubmit}>Abschicken</button>
                    </div>}
                {navigateButton &&
                    <div className="button-container">
                        <button type="button" onClick={handleBack}>Zurück</button>
                        <button type="button" onClick={handleNext}>Bestätigen</button>
                    </div>}

            </div>
        );
    }

    function renderInfoWithLink(field) {
        const labelParts = field.label.split(field.link.label);

        return (
            <div key={field.label + "_div"} style={{ width: field.width ? field.width : "100%", margin: field.margin ? field.margin : "" }} className={field.className || ""}>
                <span>
                    {renderTextWithLineBreaks(labelParts[0])}
                    <a href={field.link.url} target="_blank" rel="noopener noreferrer">{field.link.label}</a>
                    {renderTextWithLineBreaks(labelParts[1])}
                </span>
            </div>
        );
    }

    function renderTextWithLineBreaks(label) {
        const labelParts = label.split("\n");

        return (
            labelParts.map((part, index) => {
                return (
                    <span key={index}>
                        {part}
                        {index != labelParts.length - 1 && <br />}
                    </span>
                );
            })
        );
    }

    function labelWithOptionIndex(label, optionIndex) {
        return optionIndex + "_" + label;
    }

    function appendDataSelect(dataSelectIndex, dataSelectValue) {
        //console.log("dataSelectIndex: " + dataSelectIndex + " dataSelectValue: " + dataSelectValue);
        let dataField = {};
        currentFormPage?.fields?.map((field, fieldIndex) => {
            if (field.type === 'data') {
                dataField = field.data;
            }
        });
        //console.log("dataField: " + JSON.stringify(dataField));

        let newDataSelectState = [...dataSelectState];
        newDataSelectState[dataSelectIndex - 1] = dataSelectValue;

        const dataFieldKey = newDataSelectState.join(" ").toLocaleLowerCase();
        //console.log("dataFieldKey: " + dataFieldKey + " dataField[dataFieldKey]: " + dataField[dataFieldKey]);

        return dataField[dataFieldKey];
    }

    function dataSelect(label) {


        let dataField = {};
        currentFormPage?.fields?.map((field, fieldIndex) => {
            if (field.type === 'data') {
                dataField = field.data;
            }
        });

        //console.log("dataField: " + JSON.stringify(dataField));
        const dataFieldKey = dataSelectState.join(" ")?.toLocaleLowerCase();
        //console.log("dataFieldKey: " + dataFieldKey + " dataField[dataFieldKey]: " + dataField[dataFieldKey]);

        const result = dataField[dataFieldKey];

        if (result) {
            dispatch(addBestellungInput(label, result));
        } else {
            dispatch(removeBestellungInput(label));
        }

        //console.log("added to bestellung: " + label + " " + result);

        return result;
    }


    return (
        <div className="form-container">
            <h3>{currentFormPage?.headline}</h3>
            <form>
                {currentFormPage?.fields?.map((field, fieldIndex) => {
                    switch (field.type) {
                        case 'text':
                            return isValidField(field) && (
                                <div key={currentPage + "_" + fieldIndex} className={field.className || 'text-input-container'} style={{ width: field.width ? field.width : "100%", margin: field.margin ? field.margin : "", padding: field.padding ? field.padding : "" }} >
                                    <label key={currentPage + "_" + fieldIndex}>
                                        <span>{field.label + (field.isRequired ? "*" : "")} {(errorState[field.label] ? <span style={{ color: 'red', fontWeight: '600', marginLeft: '2px' }}>{errorState[field.label]}</span> : <span></span>)}</span>
                                        <input
                                            type="text"
                                            style={errorState[field.label] ? { borderColor: 'red' } : {}}
                                            placeholder={field.placeholder || ""}
                                            required={field.isRequired}
                                            onChange={e => handleInputChange(field.label, e.target.value, field.pattern, field.validationMessage)}
                                            key={"input_" + currentPage + "_" + fieldIndex}
                                            value={state[field.label]}
                                        /> </label>
                                </div>
                            );
                        case 'radio':
                            const radioOptions = field.options ? field.options : field.optionsMapDependend ? field.optionsMapDependend[state[field.dependsOn]] : [];
                            return (
                                radioOptions?.length > 0 &&
                                <div key={currentPage.label + "_" + fieldIndex + "_" + (typeof radioOptions[0] === 'string' ? radioOptions[0] : radioOptions[0].value)} style={{ width: field.width || "100%", margin: field.margin ? field.margin : "" }} className={field.className || ""}>
                                    {field.label + (field.isRequired ? "*" : "")}
                                    {radioOptions?.map((option, optionIndex) => {
                                        const optionValue = typeof option === 'string' ? option : option.value;
                                        const optionLabel = typeof option === 'string' ? option : option.label;
                                        return (
                                            <div key={optionIndex} className="radio-button-div">
                                                <input
                                                    type="radio"
                                                    id={optionValue + "_" + optionIndex}
                                                    name={field.label}
                                                    required={field.isRequired}
                                                    onChange={(event) => (handleInputChangeNonText(field.label, event.target.checked ? optionValue : null))}
                                                    defaultChecked={state[field.label] === optionValue}
                                                />
                                                <label htmlFor={optionValue + "_" + optionIndex}>
                                                    <span id={'radio_span_' + optionValue + "_" + optionIndex}>
                                                        {renderTextWithLineBreaks(optionLabel)}{field.appendDataSelect && appendDataSelect(field.dataSelectorIndex, optionValue) ? (", " + appendDataSelect(field.dataSelectorIndex, optionValue)) : ''}
                                                    </span>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            );



                        case 'checkboxGen':
                            let checkboxGenOptions = [];
                            try {
                                checkboxGenOptions = authData[field.content];
                            } catch (error) {
                                console.error("Fehler beim Parsen des Checkbox-Options-Strings:", authData[field.content]);
                                console.error("Fehler beim Parsen des Checkbox-Options-Strings:", error);
                            }

                            //console.log("checkboxGenOptions: " + JSON.stringify(checkboxGenOptions));

                            return (
                                checkboxGenOptions?.length > 0 && (
                                    <div key={`${currentPage.label}_${fieldIndex}_${checkboxGenOptions[0]}`} style={{ width: field.width || "100%", margin: field.margin || "" }} className={`checkbox-container ${field.className || ""}`}>
                                        <div className="checkbox-header">{field.label + (field.isRequired ? "*" : "")}</div>
                                        {checkboxGenOptions?.map((option, optionIndex) => (
                                            <div key={field.label + optionIndex} className="checkbox-div">
                                                <input
                                                    type="checkbox"
                                                    id={`${option}_${optionIndex}`}
                                                    name={field.label}
                                                    required={field.isRequired}
                                                    onChange={(event) => handleInputChangeNonTextRememberFalse(labelWithOptionIndex(field.label, optionIndex), event.target.checked ? option : null)}
                                                    checked={state[labelWithOptionIndex(field.label, optionIndex)] === option}
                                                />
                                                <label htmlFor={`${option}_${optionIndex}`}>
                                                    <span>
                                                        {renderTextWithLineBreaks(option)}
                                                    </span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )
                            );


                        case 'select':
                            const options = field.options ? field.options : field.optionsMapDependend ? field.optionsMapDependend[state[field.dependsOn]] : [];
                            return isValidField(field) && <div key={currentPage + "_" + fieldIndex + "_" + field.dependsOn ? state[field.dependsOn] : ""} style={{ width: field.width ? field.width : "100%", margin: field.margin ? field.margin : "" }} className={field.className || ""}>
                                <label key={field.label + "_" + fieldIndex}>
                                    {field.label + (field.isRequired ? "*" : "")}
                                    <select
                                        required={field.isRequired}
                                        disabled={!options || (field.disableWhenPrefill && state[field.label])}
                                        onChange={(event) => handleInputChangeNonText(field.label, event.target.value)}
                                        value={state[field.label]}
                                    >
                                        {options?.map((option, optionIndex) => {
                                            if (typeof option === 'string') {
                                                return <option key={optionIndex} value={option}>{option}</option>;
                                            } else if (typeof option === 'object' && option !== null) {
                                                return <option key={optionIndex} value={option.value}>{option.label}</option>;
                                            }
                                        })}

                                    </select>
                                </label>

                            </div>
                                ;
                        case 'textarea':
                            return (
                                isValidField(field) && <div key={currentPage + "_" + fieldIndex} style={{ width: field.width ? field.width : "100%", margin: field.margin ? field.margin : "" }} className={field.className || ""}>
                                    <label key={field.label + "_" + fieldIndex}>
                                        <span>{field.label + (field.isRequired ? "*" : "")} {(errorState[field.label] ? <span style={{ color: 'red', fontWeight: '600', marginLeft: '2px' }}>{errorState[field.label]}</span> : <span></span>)}</span>
                                        <textarea required={field.isRequired} onChange={(event) => handleInputChange(field.label, event.target.value, field.pattern, field.validationMessage)} defaultValue={state[field.label]} placeholder={field.placeholder || ''} />
                                    </label>
                                </div>
                            );
                        case 'infoWithLink':
                            return isValidField(field) && renderInfoWithLink(field);
                        case 'info':
                            return isValidField(field) &&
                                (
                                    <div key={currentPage + "_" + fieldIndex} style={{ width: field.width ? field.width : "100%", margin: field.margin ? field.margin : "" }} className={field.className || ""}>
                                        {renderTextWithLineBreaks(field.label)}
                                    </div>
                                );
                        case 'textDataSelect':
                            return (
                                <div key={currentPage + "_" + fieldIndex} className={field.className || 'text-input-container'} style={{ width: field.width ? field.width : "100%", margin: field.margin ? field.margin : "", padding: field.padding ? field.padding : "" }} >
                                    <label key={currentPage + "_" + fieldIndex}>
                                        <span>{field.label}</span>
                                        <input
                                            type="text"
                                            disabled={true}
                                            placeholder={field.placeholder || ""}
                                            required={field.isRequired}
                                            onChange={e => handleInputChange(field.label, e.target.value, field.pattern, field.validationMessage)}
                                            key={"input_" + currentPage + "_" + fieldIndex}
                                            defaultValue={state[field.label]}
                                            value={dataSelect(field.label) || state[field.label]}
                                        /> </label>
                                </div>
                            );



                        // ...weitere Feldtypen...
                        default:
                            return null;
                    }

                })}
                <div className="button-container">
                    <button type="button" onClick={handleBack} disabled={currentPage === -1}>Zurück</button>

                    <button type="button" onClick={handleNext} disabled={currentPage === formSchema.pages.length + 1 || !allRequiredFieldsFilled || !allFieldsValid}>Weiter</button>
                </div>
            </form>
        </div>
    );
}

export default Form;
