import { Backdrop, CircularProgress, Modal } from "@mui/material";

import React, { useContext, useCallback, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { Box } from "@mui/system";

export const BestellungModalButton = (props) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: props.maxWidth || 400,
        bgcolor: "#f3f4f6",
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [waitingOpen, setWaitingOpen] = React.useState(false);
    const handleWaitingOpen = () => setWaitingOpen(true);
    const handleWaitingClose = () => setWaitingOpen(false);

    const [bestellung, setBestellung] = React.useState({});
    const [formSchema, setFormSchema] = React.useState({});

    const authCtx = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_HOST
        ? process.env.REACT_APP_API_HOST
        : "https://sfpapi.videocreator.net";

    const bestellungParameter = '?bestellunguuid=' + props.bestellunguuid;

    const bestellungFetch = useCallback(async () => {
        const bestellungData = await fetch(apiUrl + "/userinput/bestellung" + bestellungParameter, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            },
        });
        const bestellungDataJSON = await bestellungData.json();
        //console.log('bestellungDataJSON Bestellstrecke', JSON.stringify(bestellungDataJSON.bestellStrecke));
        setBestellung(JSON.parse(bestellungDataJSON.bestellStrecke));

        setFormSchema(bestellungDataJSON.formSchema);

        if (bestellungDataJSON.formSchema && bestellungDataJSON.formSchema.pages) {
            bestellungDataJSON.formSchema.pages.forEach((page, index) => {
                //console.log(`Seite ${index + 1}:`, page);

                if (page.fields) {
                    page.fields.forEach((field, fieldIndex) => {
                        //console.log(`Feld ${fieldIndex + 1} von Seite ${index + 1}:`, field);
                    });
                } else {
                    //console.log(`Seite ${index + 1} hat keine Felder.`);
                }
            });
        }


    }, [authCtx, apiUrl, bestellungParameter]);

    useEffect(() => {
        if (open) {
            bestellungFetch();
        }
    }, [open, bestellungFetch]);


    // const [forceRender, setForceRender] = React.useState(0);

    // useEffect(() => {
    //     setForceRender(prev => prev + 1);
    //     console.log('forceRender', forceRender);
    // }, [bestellung, formSchema]);

    return (
        <div>
            <div className={"e-button"} onClick={handleOpen}>Bestellung anzeigen</div>
            <Modal
                open={open}
                //onClose={handleClose} Uncomment this if you need the functionality
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style}>
                    {/* Begin New Content */}
                    <div className="form-container">
                        <h1>Übersicht</h1>
                        <p>Hier sind die von Ihnen eingegebenen Informationen:</p>
                        <table>
                            <tbody>
                                {formSchema?.pages?.map(page => {
                                    return page.fields.map(field => {
                                        return (
                                            bestellung[field.label] && <tr key={field.label}>
                                                <td>{field.label}</td>
                                                {typeof bestellung[field.label] !== 'undefined' && <td>{bestellung[field.label]}</td>}
                                            </tr>
                                        );
                                    });
                                })}
                            </tbody>
                        </table>

                    </div>
                    {/* End New Content */}

                    <div className={"c-ButtonBarModal"}>
                        <div style={{ position: "left" }}>
                            <div
                                className={"e-button gray"}
                                onClick={handleClose}
                            >
                                Schließen
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waitingOpen}
                onClick={handleWaitingClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );

};  
