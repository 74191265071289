import * as React from "react";

import "./CampaignGFL24.scss"

const CGflStep4 = ({
  campaignData,
  handleActiveStepChange,
  handleReset,
  currentPage,
  bestellstrecke,
}) => {
  return (
    <div className={"Step C2Step4 c-Datenschutz"}>
      <div className={"content"}>
        <div className={"datenschutz"}>
          <h4>
            Bitte bestätigen Sie die Nutzungsbedingungen für Medien dieser
            Kampagne.
          </h4>
          <br />
          <h4>1. Leistung von S-Communication Services</h4>
          <p>
            Die S-Communication Services GmbH („S-Com“) erbringt für das Institut folgende Leistungen:
          </p>
          <ul className="main">
            <li>15 Video-Individualisierungen: </li>
            <ul className="sub">
              <li>1x 01 PK PREROLL Falsche Ratgeber 20Sek 1920x1080</li>
              <li>1x 03 PK PREROLL Friseur 15Sek 1920x1080</li>
              <li>1x 04 PK PREROLL Sprachassistent 15Sek 1920x1080</li>
              <li>1x 05 PK PREROLL Mama 15Sek 1920x1080</li>
              <li>1x 06 PK BUMPER Friseur 6Sek 1920x1080</li>
              <li>1x 07 PK BUMPER Sprachassistent 6Sek 1920x1080</li>
              <li>1x 08 PK BUMPER Mama 6Sek 1920x1080</li>
              <li>1x 21 PK DOOH ohne Ton Friseur 10Sek 1920x1080</li>
              <li>1x 22 PK DOOH ohne Ton Friseur 10Sek 1080x1920</li>
              <li>1x PK REEL AD Mama 1080x1920</li>
              <li>1x PK REEL AD Sprachassistent 1080x1920</li>
              <li>1x PK REEL AD Friseur 1080x1920</li>
              <li>1x PK ANIMATION INTEREST Weiterbildung 1080x1920</li>
              <li>1x PK ANIMATION INTEREST Moebel 1080x1920</li>
              <li>1x PK ANIMATION INTEREST Modernisierung 1080x1920</li>
            </ul>
            <li>
              Individualisierung der Videos entsprechend den Angaben in der
              Bestellstrecke
            </li>
          </ul>
          <p>
            Video-Individualisierungen außerhalb der vorgegebenen Optionen sind
            ausgeschlossen.
          </p>

          <h4>2. Lizenz & Nutzungsbedingungen</h4>
          <ul className="main">
            <li>TV: keine Lizenz<br />
              Das zeitlich beschränkte, einfache, nicht-exklusive Recht zur Nutzung der TV-Spots in Rundfunk und Fernsehen sowie fernsehähnlichen Formaten und Medien, wie beispielsweise Pay-TV, InternetTV, SmartTV, etc.</li>
            <li>Digital: 01.07.2023 bis einschließlich 31.12.2024<br />
              Das zeitlich beschränkte Recht, die Werbespots im Internet, Intranet, Social Media Dienste, Mobilen Diensten einzuspeisen und dort öffentlich vorzuführen - z. B. als Teaser, Banner, PreRoll oder auf Microsites. Miteingeschlossen ist das Recht, die Spots einer Vielzahl von Nutzern so zur Verfügung zu stellen, dass diese auf Abruf mittels eines Fernseh-, Computer-, Mobil- oder sonstigen Geräts die Spots empfangen und wiedergeben können.</li>
            <li>PoS: 01.07.2023 bis einschließlich 31.12.2024<br />
              Das zeitlich beschränkte Recht die Werbespots am Points of Sale, d.h. Verkaufs-, Angebots- und Präsentationsorten und -veranstaltungen, sowie Geschäftsstellen, an (Verkaufs-) Ausstellungen, Kongressen, Schulungsveranstaltungen, auf Messen und ähnlichen Veranstaltungen öffentlich vorzuführen.</li>
          </ul>
          <p>
            Nach Ablauf der Nutzungsfrist dürfen keinerlei Kommunikationsmittel (digital und print) mehr eingesetzt
            oder in Umlauf gebracht werden, die oben genanntes Bildmaterial zeigen. Sofern die Einstellung auf Social
            Media Plattformen zulässig ist, sind insbesondere diese nach Ablauf der Frist zu entfernen. Ob
            zwischenzeitlich eine Verlängerung des Nutzungszeitraums erfolgt ist, können Sie dem digitalen Bildarchiv
            der S-Com entnehmen oder bei der Kundenberatung erfragen.</p>
          <div className="einruecken">




            <br />
            <h4>A c h t u n g !</h4>
            <h4><u>Bitte beachten Sie aus lizenzrechtlichen Gründen folgendes:</u></h4>
            <p>
              Es ist ausdrücklich nur gestattet Werbespots der jeweils aktuellen GSK im angegebenen Nutzungszeitraum innerhalb Deutschlands zu nutzen bzw. einzubinden. Für ältere Werbespots oder Versionen bestehen keine Nutzungsrechte mehr, ein Einsatz ist daher nicht möglich. Eine Nutzung außerhalb der angegebenen Nutzungszeiträume führt zu Lizenzverstößen und berechtigt die Rechteinhaber zu Unterlassungsaufforderungen und hohen Schadenersatzforderungen gegenüber den nutzenden Sparkassen.
            </p>
          </div>

          <h4>3. Nicht-Übertragbarkeit</h4>
          <p>Die hier eingeräumten Nutzungsrechte sind nicht auf Dritte übertragbar.</p>

          <h4>4. Bildbearbeitungsrechte</h4>
          <p>Die Bearbeitung, Umgestaltung oder Manipulation des Bildmotivs ist unzulässig. Dies gilt insbesondere für jegliche Entstellung, beispielsweise durch Nachfotografieren, zeichnerische Verfälschung, Fotocomposing oder andere Hilfsmittel. Ausgenommen davon ist die Veränderung der Bildgröße (Vergrößerung/Verkleinerung) und die Farbraumkonvertierung.
          </p>

          <h4>5. Freistellung/Verantwortung bei Verstößen gegen diese Nutzungsbedingungen</h4>
          <p>Im Falle des Verstoßes gegen den Nutzungsvertrag, insbesondere bei unberechtigter Nutzung, Bearbeitung
            oder Weitergabe der Inhalte oder Teile daraus, hat das Institut die S-Com von allen sich hieraus ergebenden
            Ansprüchen Dritter freizustellen und alle der S-Com entstehenden Schäden zu ersetzen.</p>

          <h4>6.Wirksamwerden/Laufzeit</h4>
          <p>Mit Bestätigung des Buttons „Bestätigen und Video(s) kostenpflichtig erstellen“ akzeptieren Sie die oben genannten Lizenzbedingungen. Der Vertrag endet automatisch mit dem Ablauf der Nutzungsrechte der Videos.</p>

          <p>Ergänzend gelten die Nutzungsbedingungen VIA sowie die Regelungen des zwischen den
            Vertragsparteien geschlossenen Rahmenvertrages.</p>

        </div>



        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(currentPage - 1);
            }}
          >
            Zurück
          </div>

          {
            <div
              className={"e-button"}
              onClick={() => {
                handleReset();
                handleActiveStepChange(currentPage + 1);
              }}
            >
              Bestätigen und
              Video(s) kostenpflichtig erstellen
            </div>
          }
        </div>
      </div>
    </div >
  );
};

export default CGflStep4;
