import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import { AuthContextProvider } from "./store/auth-context";

import { Provider } from "react-redux";
import store from './store/redux-store';

ReactDOM.render(
  <AuthContextProvider>
    <BrowserRouter>
      <Layout>
        <Provider store={store}>
          <App />
        </Provider>
      </Layout>
    </BrowserRouter>
  </AuthContextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
