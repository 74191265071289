
class DateConverter{

    static formatIsoToDDMMYYYY = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
      
        return `${day}.${month}.${year}`;
      };

      static formatIsoToDDMMYY = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear()-2000;
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
      
        return `${day}.${month}.${year}`;
      };

      static formatIsoToDDMMYYHHMM = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear()-2000;
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2,"0");
        const minutes = String(date.getMinutes()).padStart(2,"0");
        
        return `${day}.${month}.${year} ${hours}:${minutes}`;
      }



      static fillMissingDates = (data, kumultativ = false) => {
        if (!data || data.length === 0) {
          return [];
        }
        const startDate = new Date(data[0].date);
        let endDate = new Date(data[data.length - 1].date);
      
        // Hinzufügen des heutigen Datums
        const today = new Date();
        today.setHours(0, 0, 0, 0);
      
        const filledData = [];
        let currentDate = startDate;
      
        const dataIndex = {};
        data.forEach((item) => {
          dataIndex[new Date(item.date).getTime()] = item;
        });
      
        // Definieren von prevValue und Hinzufügen des heutigen Datums
        let prevValue = data[data.length - 1].count;
        if (endDate < today) {
          endDate = today;
          dataIndex[today.getTime()] = { date: today.toISOString(), count: kumultativ?prevValue:0 };
        }
      
        while (currentDate <= endDate) {
          const timestamp = currentDate.getTime();
          if (dataIndex[timestamp]) {
            filledData.push(dataIndex[timestamp]);
            prevValue = dataIndex[timestamp].count;
            if (!kumultativ) {
              prevValue = 0;
            }
          } else {
            filledData.push({
              date: new Date(currentDate).toISOString(),
              count: prevValue,
            });
          }
      
          currentDate.setDate(currentDate.getDate() + 1);
        }
      
        return filledData;
      };
      
      
      
      
}

export default DateConverter;