import { Backdrop, CircularProgress, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import React, { useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import { Box } from "@mui/system";





const RenderButton = (props) => {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: props.maxWidth || 400,
    bgcolor: "#f3f4f6",
    boxShadow: 24,
    p: 4,
  };

  const [previewLink, setPreviewLink] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [waitingOpen, setWaitingOpen] = React.useState(false);
  const handleWaitingOpen = () => setWaitingOpen(true);
  const handleWaitingClose = () => setWaitingOpen(false);

  const authCtx = useContext(AuthContext);
  let userInputs = useSelector((state) => state.userInput);

  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";

  const handleOnClick = async () => {
    handleWaitingOpen();
    if (process.env.REACT_APP_FTP_OUTPUT) {
      userInputs = {
        ...userInputs,
        ftpOutputFolder: process.env.REACT_APP_FTP_OUTPUT,
      };
    }

    const bodyText = JSON.stringify({
      campaignuuid: props.campaignuuid,
      userinputKeyValueInput: JSON.stringify(userInputs),
    });

    //console.log("bodyText: " + bodyText);

    setPreviewLink("");
    const imageFetch = await fetch(apiUrl + "/userinput/render", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: bodyText,
    });

    const imageURL = await imageFetch.json();
    setPreviewLink(imageURL);
    handleWaitingClose();
    handleOpen();
  };

  return (
    <div>
      <div className={"e-button"} onClick={handleOnClick}>Vorschau anzeigen</div>
      <Modal
        open={open}
        //onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img style={{ maxWidth: props.maxWidth ? props.maxWidth : "400px", height: "auto" }} src={previewLink} alt="preview"></img>
          <div className={"c-ButtonBarModal"}>
            {
              <div style={{ position: "left" }}>
                <div
                  className={"e-button gray"}
                  onClick={handleClose}
                >
                  Zurück
                </div></div>}

            {
              <div style={{ position: "right" }}>
                <div
                  className={"e-button"}
                  onClick={() => props.weiterAction(props.weiterActionProp)}
                >
                  Weiter
                </div></div>}
          </div>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={waitingOpen}
        onClick={handleWaitingClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default RenderButton;
