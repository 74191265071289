// src/components/AdminNavbar/AdminNavbar.js
//import css
import "./AdminNavbar.css";

import React from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../store/auth-context";

const AdminNavbar = () => {
    const authCtx = React.useContext(AuthContext);

    return (

        <nav className="c-AdminNavbar">
            {authCtx.role === "admin" && (
                <div className="container">
                    <ul>
                        <li>
                            <NavLink to="/admin" end activeclassname="active">
                                Übersicht
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/KampagnenFreischaltungen" activeclassname="active">
                                Kampagnen-Freischaltungen
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/Abomodell" activeclassname="active">
                                Abomodell
                            </NavLink>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default AdminNavbar;
