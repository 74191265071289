import * as React from "react";
const CSpStep2 = ({
  campaignData,
  handleActiveStepChange,
  handleReset,
}) => {
  return (
    <div className={"Step C2Step4 c-Datenschutz"}>
      <div className={"content"}>
        <div className={"datenschutz"}>
          <h4>
            Bitte bestätigen Sie die Nutzungsbedingungen für Medien dieser
            Kampagne.
          </h4>
          <br />
          <h4>Leistung von S-Communication Services</h4>
          <p>
            Die S-Communication Services GmbH („S-Com“) erbringt für das Institut folgende Leistungen:
          </p>
          <ul className="main">
            <li>Video-Individualisierungen: </li>
            <ul className="sub">
              <li>1x Video im Format 16:9, H.264/MP4, LBS Anschlussfinanzierer:innen, Spot</li>
              <li>1x Video im Format 16:9, H.264/MP4, LBS Anschlussfinanzierer:innen, Bumper</li>
            </ul>
            <li>
              Individualisierung der Videos entsprechend den Angaben in der Bestellstrecke
            </li>
          </ul>
          <p>
            Video-Individualisierungen außerhalb der vorgegebenen Optionen sind ausgeschlossen.
          </p>

          <h4>Lizenzbedingungen</h4>
          <p>
            Das Institut erhält zum 17.03.2023 bis einschließlich 31.12.2024 das einfache, nicht-exklusive
            Recht, die erstellten Leistungen im Gebiet der Bundesrepublik Deutschland für die
            Veröffentlichung im Internet (national) inkl. Paid Media zu nutzen.
          </p>
          <h4>Wirksamwerden/Laufzeit</h4>
          <p>
            Mit Bestätigung des Buttons „Bestätigen und Video(s) erstellen“ akzeptieren Sie die oben genannten
            Lizenzbedingungen. Der Vertrag endet automatisch mit dem Ablauf der Nutzungsrechte der
            Videos.
          </p>
          <p>
            Ergänzend gelten die Nutzungsbedingungen VIA sowie die Regelungen des zwischen den Vertragsparteien geschlossenen Rahmenvertrages.{" "}
          </p>
        </div>

        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(1);
            }}
          >
            Zurück
          </div>

          {
            <div
              className={"e-button"}
              onClick={() => {
                handleReset();
                handleActiveStepChange(3);
              }}
            >
              Bestätigen und Video(s) erstellen
            </div>
          }
        </div>
      </div>
      <div className={"content-sidebar"}></div>
    </div>
  );
};

export default CSpStep2;
